export const convertMembership = {
	methods: {
		async convertToPayMembership(userCnt) {
			//유료 멤버십으로 전환할 때, 기존 무료 멤버십의 code가 정부지원사업 코드인 경우 예외처리를 위한 코드
			this.setGovermentSupport(this.usingMembership.baseChannelInfoModel.baseChannelModel.code);

			// 유료 멤버십 전환
			const payMembership = await this.$store.dispatch('fetchMembershipBaseChannelChanged', {
				id: this.usingMembership.id,
				baseChannelCode: window.__env.code.SAAS,
			});
			payMembership['userCnt'] = userCnt;
			this.usingMembership = payMembership;
			await this.setUsingMembershipInfos(this.usingMembership);
			await this.setUsingBaseInfo();

			this.usingMembership['userCnt'] = userCnt;
			this.existingUserCnt = 0; // 기존 사용자 수 0으로 세팅
			this.totUserCnt = userCnt; // 처음 로딩 시, 사용자 수는 기존과 동일
			// 환불 가능금액 조회
			const paymentDetail = await this.$store.dispatch('fetchMembershipPaymentDetail', this.usingMembership.id);
			await this.$store.commit('SET_REFUNDABLE_AMOUNT', paymentDetail.remainPrice);
		},
		async convertToOriginMembership() {
			this.$router.go();
		},

		setGovermentSupport(currentCode) {
			let isGovermentSupport = this.$constants.GOVERMENT_SUPPORT_BASE_CHANNEL_CODES.includes(currentCode);

			this.$store.commit('SET_GOVERNMENT_SUPPORT_BASE_CHANNEL_CODE', isGovermentSupport);
		},
	},
};
