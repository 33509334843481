export const product = {
	methods: {
		serviceNameDisplay(service) {
			const chargeType = service.chargeType;
			const serviceName = service.serviceCategoryModel.name;
			if (chargeType == 'FREE') {
				return '공유형 무료';
			} else {
				return serviceName;
			}
		},
	},
	data() {
		return {
			billingType: {
				NONE: '',
				ONCE: '(1회)',
				MONTHLY: '(월)',
				ESTIMATE: '(견적가)',
			},
			optionBillingType: {
				NONE: '',
				ONCE: '(1회)',
				MONTHLY: '(월)',
				ESTIMATE: '(견적가)',
			},
		};
	},
};
