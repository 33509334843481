<template>
	<div class="card list group column">
		<div class="group row">
			<div class="group headline">
				<h4 class="">
					가상 도메인
					<span v-if="item.displaySectionModel.useTooltip" class="">
						<i class="ic-16 ic-info">
							<span class="tooltip-layer left">
								{{ item.displaySectionModel.tooltipDesc }}
								></span
							>
						</i>
					</span>
				</h4>
				<div v-if="item.displaySectionModel.useIcon" class="badge">
					{{
						item.displaySectionModel.iconType === 'HOT'
							? 'HOT'
							: item.displaySectionModel.iconType === 'RECOMMENDATION'
							? '추천'
							: ''
					}}
				</div>
				<div class="badge line primary" v-if="item.usingCount">사용중</div>
				<div class="group row">
					<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
						<span class="txt">견적가</span>
					</template>
					<template v-else>
						<span v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'" class="price"
							>{{ item.channelItemSalesSectionModel.price | currency }}원 /{{
								item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit
							}}개</span
						>
						<span v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'" class="price"
							>{{ item.channelItemSalesSectionModel.price | currency }}원</span
						>
					</template>
					<span class="price billing_type">
						{{ item.channelItemSalesSectionModel.billingType | billingTypeDesc }}
					</span>
					<span
						class="group-inp-num space-between"
						v-if="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity"
					>
						<button
							class="line mini"
							v-on:click="
								openAlert('가상 도메인 수량 취소', '수량 취소는 삭제하고자 하는 도메인 옆 X 버튼을 이용해주세요.')
							"
						>
							<i class="ic-16 ic-reduce"></i>
						</button>
						<span class="txt">
							{{ item.orderCount }}
						</span>
						<button
							class="line mini"
							@click="
								addDomainText(item);
								upCntOptionItem(item, optionPerService.service);
							"
						>
							<i class="ic-16 ic-grow"></i>
						</button>
					</span>
					<span :class="[item.gapCount !== 0 ? 'txt modified' : '']">
						<template v-if="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity">
							<template v-if="item.gapCount > 0"> 기존 {{ item.usingCount }} + 추가 {{ item.gapCount }}</template
							><template v-if="item.gapCount < 0"> 기존 {{ item.usingCount }} - 취소 {{ -1 * item.gapCount }}</template>
						</template>
					</span>
				</div>
			</div>
		</div>
		<div class="group column" v-if="item.orderCount > 0">
			<table class="">
				<tbody>
					<tr
						class="table-row"
						v-for="index in item.orderCount"
						:key="optionPerService.service.code + item.itemCode + index"
					>
						<td class="tit">
							<i class="ic-16 ic-depth"> </i>
							<span class="txt"> 가상 도메인 {{ index }} </span>
						</td>
						<td class="">
							<div class="group-inp group row mb-0">
								<input
									:disabled="index <= item.usingCount"
									class=""
									type="text"
									value="sample"
									v-model="item.domainText[index - 1].text"
									placeholder="sample.com"
								/>
							</div>
							<button
								class="icon"
								title="삭제"
								v-on:click="
									checkDownCntOptionItem(
										item,
										optionPerService.service,
										'VIRTUAL_DOMAIN',
										index,
										item.domainText[index - 1].isExisting,
									)
								"
							>
								<i class="ic-16 ic-close"></i>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { option } from '@/components/apply/user/store/addExtension/optionItem/MixinsOption.js';
export default {
	mixins: [option],
	props: ['optionPerService', 'item'],
	methods: {
		addDomainText(item) {
			if (item.orderCount >= item.domainText.length) {
				item.domainText.push({ text: '', isExisting: false });
			}
		},
	},
};
</script>
<style scoped>
.billing_type {
	margin-left: -10px;
}
</style>
