<template>
	<Fragment>
		<div class="container">
			<div class="body-contents select-service reorder">
				<h2 class="heading-3-b">추가/연장주문</h2>
				<ol class="stepper type2">
					<li class="step on">
						<a href="#" title="step01">
							<i>1</i>
							<span>서비스 및 상품 선택</span>
						</a>
					</li>
					<li class="step">
						<a href="#" title="step02">
							<i>2</i>
							<span>약관 동의 및 결제</span>
						</a>
					</li>
					<li class="step">
						<a href="#" title="step03">
							<i>3</i>
							<span>주문 완료</span>
						</a>
					</li>
				</ol>
				<div class="section-wrap fluid">
					<div class="section-main">
						<div class="tab-wrap">
							<membership-tab @getSelected="getSelected"></membership-tab>
							<add-extension
								v-if="this.selectMembershipId"
								:key="this.selectMembershipId"
								:membership-id="this.selectMembershipId"
							></add-extension>
						</div>
					</div>
					<side-bar id="sideBar" v-on:openModal="openModal" :type="'ADDEXTENSION'"></side-bar>
				</div>
			</div>
		</div>
	</Fragment>
</template>
<script>
import mixinsAlert from '@/components/common/mixinsAlert';
import AddExtension from '@/views/order/AddExtension';
import ModalComponent from '@/components/code/modal/ModalComponent';
import SideBar from '@/components/apply/user/store/SideBar.vue';
import MembershipTab from '@/components/common/MembershipTab';
import { DEFAULT_MODAL_STYLE } from '@/utils/constants';

export default {
	mixins: [mixinsAlert],
	components: { MembershipTab, AddExtension, SideBar },
	data() {
		return {
			selectMembershipId: '',
		};
	},
	computed: {},
	watch: {},
	async created() {},
	methods: {
		getSelected(membershipId) {
			this.selectMembershipId = membershipId;
		},
		openModal(modalData) {
			this.$modal.show(
				ModalComponent,
				{
					item: modalData,
				},
				Object.assign({}, DEFAULT_MODAL_STYLE, { name: 'customerUserApply' }, { draggable: true }),
				{
					closed: this.closeModal,
				},
			);
			this.modalData = modalData;
			this.modalFlag = true;
		},
		closeModal() {
			this.modalFlag = false;
			this.modalData = null;
		},
	},
};
</script>
<style>
@import '../../assets/css/order.css';
</style>
