import { productClassifier } from '@/views/mixinProductClassifier';
export const extensionPeriodChange =
	// 연장 기간 변경 시
	{
		mixins: [productClassifier],
		methods: {
			async checkBaseChannelIsConvertable(baseChannelId) {
				const conversionProduct = await this.$store.dispatch('checkBasechannelIsConvertable', baseChannelId);
				await this.$store.commit('SET_CONVERSION_PRODUCT', conversionProduct);
			},
			async convertToNewMembership(conversionId) {
				//신요금제 전환
				const conversionMembership = await this.$store.dispatch('fetchConversionMembership', {
					membershipId: this.usingMembership.id,
					baseChannelId: conversionId,
				});

				if (conversionMembership) {
					this.usingMembership = conversionMembership;
					this.remainDay = this.extensionPeriod; // 신요금제 전환 대상이면 [추가연장 == 추가하면서 연장] 하므로 remainDay 는 연장기간과 동일
					this.$store.commit('SET_REMAIN_DAY', this.remainDay);
					await this.setUsingMembershipInfos(this.usingMembership);
					await this.setUsingBaseInfo();
					// 신요금제 멤버십에 맞는 옵션서비스 갱신 후 사용자 수 변경해야 함
					this.totUserCnt = this.usingMembership.totalUserCnt;
					conversionMembership['userCnt'] = this.totUserCnt;
				} else {
					// 서비스 전환 실패시 연장 불가능하도록 라디오 버튼 value 변경
					// alert는 interceptor.js에서 처리
					this.extensionPeriod = 0;
				}
			},
			isExtensionOrAddExtensionOrderWithConversionProduct(convertable) {
				// 멤버십 만료일이 패치일(2024-03-28) 이후인 경우, 신요금제 적용 대상
				const patchedDate = this.$moment({ year: 2024, month: 2, date: 28 }).format('YYYY-MM-DD');
				const membershipExpirationDate = this.$moment(
					this.usingMembership.membershipBaseInfoModel.expirationDate,
				).format('YYYY-MM-DD');
				this.$store.commit(
					'SET_EXTENSION_OR_ADDEXTENSION_ORDER_WITH_CONVERSION_PRODUCT',
					convertable && membershipExpirationDate >= patchedDate,
				);
				return this.$store.getters.isExtensionOrAddExtensionOrderWithConversionProduct;
			},
		},
		watch: {
			async extensionPeriod() {
				this.waitExtensionPeriod = true;
				this.$store.commit('SET_USE_PERIOD', this.extensionPeriod);
				if (this.extensionPeriod === 0) {
					// 신요금제 전환용 임시 멤버십 -> 사용중인 멤버십으로 전환
					await this.setUsingMembershipInfo(this.usingMembership.id);
					this.remainDay = await this.getRemainDays(); // 남은 사용일 수
					this.$store.commit('SET_REMAIN_DAY', this.remainDay);
					this.$store.commit('SET_EXTENSION_OR_ADDEXTENSION_ORDER_WITH_CONVERSION_PRODUCT', false); // 초기화
					await this.setUsingBaseInfo();

					// 연장 데이터 다 빼기
					/* 1) 사용자 수 취소 reset
				   2) 기본서비스, 선택 부가서비스 연장 data들 store reset
				   3) 기존사용 & 추가 옵션 연장 data들 store reset
				*/
					//1) 사용자 수 취소 reset
					if (this.addUserCnt < 0) {
						this.totUserCnt = this.existingUserCnt;
					}
					// 2-1) 기본서비스, 선택 부가서비스 연장 data들 store reset
					// 기본서비스 연장 store data reset
					const extensionBaseItem = { ...this.baseChannelList[0] };
					await this.commitWithPayload('SERVICE', 'REMOVE_SERVICE', 'BASE', extensionBaseItem, 'EXTENSION');
					// 2-2) 선택 부가서비스 연장 store data reset
					const selectedAddonChannelList = this.addonChannelList.filter((addonChannel) => addonChannel.isSelected);
					if (selectedAddonChannelList.length > 0) {
						selectedAddonChannelList.reduce((previous, current) => {
							const extensionItem = { ...current };
							return previous.then(async () => {
								await this.commitWithPayload('SERVICE', 'REMOVE_SERVICE', 'ADDON', extensionItem, 'EXTENSION');
							});
						}, Promise.resolve());
					}
					// 3) 기본서비스 옵션, 부가서비스 옵션 연장 data들 store reset

					let usingAccountSystemCode = null; //사용중인 경리회계 서비스
					if (this.usingMembership.addonChannelInfoModels.length > 0) {
						const idx = this.usingMembership.addonChannelInfoModels.findIndex((addon) =>
							this.isAccountAddonChannel(addon.addonChannelModel),
						);
						if (idx > -1) {
							usingAccountSystemCode = this.usingMembership.addonChannelInfoModels[idx].addonChannelModel.code;
						}
					}

					this.addonChannelList.forEach((addon) => {
						if (usingAccountSystemCode) {
							if (this.isAccountAddonChannel(addon)) {
								if (usingAccountSystemCode !== addon.code) {
									addon.isSelected = false;
									addon.visible = false;
								} else {
									addon.isSelected = true;
									addon.visible = true;
								}
							}
						}
					});
					// 옵션상품들 연장 데이터 다 지우기
					await this.availableOptionItemsPerService.reduce((previous, current) => {
						return previous.then(async () => {
							if (current.service.usingCount == 1) {
								const serviceType = current.service.code.indexOf('ADDON-') > -1 ? 'ADDON' : 'BASE';
								await current.options.reduce((previousOption, currentOption) => {
									return previousOption.then(async () => {
										await currentOption.items.reduce((previousOption, currentItem) => {
											return previousOption.then(async () => {
												if (currentItem.usingCount > currentItem.orderCount) {
													currentItem.orderCount = currentItem.usingCount;
													await this.addUpdateOptionByCase(currentItem, serviceType, current.service);
												}
											});
										}, Promise.resolve());
									});
								}, Promise.resolve());
							}
						});
					}, Promise.resolve());
				} else {
					// 신요금제 전환 대상일 경우 임시 멤버십으로 전환
					const conversionProduct = this.$store.getters.getConversionProduct;
					if (this.isExtensionOrAddExtensionOrderWithConversionProduct(conversionProduct.convertable)) {
						await this.convertToNewMembership(conversionProduct.conversionId);
					}

					const { data } = await this.$store.dispatch('fetchEndDate', {
						startDate: this.$store.getters.getDates.extensionStartDate,
						period: this.extensionPeriod,
						standardDay: this.usingMembership.endDateStandard,
					});
					const extensionEndDate = data;
					await this.$store.commit('SET_DATES', {
						extensionEndDate: extensionEndDate,
					});
					// 기간 연장 (연장 기간 * 총 인원)
					/* 1) 기본서비스, 부가서비스 연장 data 재 계산
				   2) 기본서비스 옵션, 부가서비스 옵션 연장 data 재 계산
				*/
					// 1-1) 기본서비스 data 재 계산
					let baseItem = this.cloneObj(this.baseChannelList[0]);
					await this.setChannelCalcInfo(baseItem, 'BASE');

					// 1-2) 부가서비스 연장 data 재 계산
					const addonItemList = this.addonChannelList.filter((addonChannel) => addonChannel.isSelected);
					if (addonItemList.length > 0) {
						await addonItemList.reduce((previous, current) => {
							return previous.then(async () => {
								let extensionItem = this.cloneObj(current);
								await this.setChannelCalcInfo(extensionItem, 'ADDON');
							});
						}, Promise.resolve());
					}
				}
				// 옵션 상품 재계산
				await this.availableOptionItemsPerService.reduce((previousService, optionPerService) => {
					return previousService.then(async () => {
						let serviceType = '';
						let freeTrialPolicyModel = null;
						let isSelected = true;
						if (optionPerService.service.code.indexOf('ADDON-') > -1) {
							serviceType = 'ADDON';
							freeTrialPolicyModel = optionPerService.service.freeTrialPolicyModel;
							isSelected = await this.isSelectedAddon(optionPerService.service.code);
						} else {
							serviceType = 'BASE';
						}
						const service = {
							name: optionPerService.service.name,
							code: optionPerService.service.code,
							serviceCategoryModel: optionPerService.service.serviceCategoryModel,
							defaultFeePolicyModel: optionPerService.service.defaultFeePolicyModel,
							addUserIntervalPolicyModel: optionPerService.service.addUserIntervalPolicyModel,
							membershipFeePolicyModel: optionPerService.service.membershipFeePolicyModel,
							freeTrialPolicyModel: freeTrialPolicyModel,
							chargeType: optionPerService.service.chargeType,
							termsSectionModel: optionPerService.service.termsSectionModel,
							usingCount: 1,
							isSelected: isSelected,
						};
						await optionPerService.options.reduce((previousOption, option) => {
							return previousOption.then(async () => {
								await option.items.reduce((previousItem, item) => {
									return previousItem.then(async () => {
										await this.addUpdateOptionByCase(item, serviceType, service);
									});
								}, Promise.resolve());
							});
						}, Promise.resolve());
					});
				}, Promise.resolve());
				this.waitExtensionPeriod = false;
			},
		},
	};
