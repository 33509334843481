import { productClassifier } from '@/views/mixinProductClassifier';
export const usingMembership = {
	mixins: [productClassifier],
	methods: {
		async setUsingMembershipInfo(membershipId) {
			const membership = await this.$store.dispatch('fetchMembership', membershipId);
			const usageStorage = await this.$store.dispatch('fetchCompanyUsage', membershipId);
			await this.$store.commit('SET_COMPANY_USAGE_STORAGE', usageStorage);
			await this.setUsingMembershipInfos(membership);
		},
		async setUsingMembershipInfos(data) {
			this.$store.commit('CLEAR_ALL_ADD_EXTENSION_INFO');
			this.setUsingMembershipUsePeriod(data);
			this.setUsingMembershipExpirationDate(data);
			this.checkIsPreMembership(data);
			this.setMembershipStatus(data);
			this.setUsingMembershipBaseServie(data);
			this.setDates(data);
			this.usingMembership = data;

			// data에 usePeriod 항목 추가
			await this.$store.commit('SET_USING_MEMBERSHIP', this.usingMembership); // store에 현재 사용중 멤버십 정보 setting
		},
		setUsingMembershipUsePeriod(data) {
			data['usePeriod'] = this.getUsePeriod(
				data.membershipBaseInfoModel.startDate,
				data.membershipBaseInfoModel.expirationDate,
			); // 사용기간
		},
		setUsingMembershipExpirationDate(data) {
			// usePeriod : 기존 사용기간

			if (data.status == 'END') {
				const now = new Date();
				let year, month, day, resultDateStr;
				// 종료된 멤버십은 expirationDate를 어제날짜로
				const yesterday = new Date(now.setDate(now.getDate() - 1));
				year = yesterday.getFullYear();
				month = yesterday.getMonth() + 1;
				month = month >= 10 ? month : '0' + month;
				day = yesterday.getDate();
				day = day >= 10 ? day : '0' + day;
				resultDateStr = year + '-' + month + '-' + day;
				data.baseChannelInfoModel.endDate = resultDateStr;
				data.membershipBaseInfoModel.expirationDate = resultDateStr;
			}

			if (
				data.membershipChargeType === 'FREE' ||
				(this.originChargeType === 'FREE' && this.currentChargeType === 'PAY')
			) {
				const now = new Date();
				let year, month, day, resultDateStr;
				// 종료된 멤버십은 expirationDate를 어제날짜로
				const yesterday = new Date(now.setDate(now.getDate() - 1));
				year = yesterday.getFullYear();
				month = yesterday.getMonth() + 1;
				month = month >= 10 ? month : '0' + month;
				day = yesterday.getDate();
				day = day >= 10 ? day : '0' + day;
				resultDateStr = year + '-' + month + '-' + day;
				// data.baseChannelInfoModel.startDate = resultDateStr;
				// data.baseChannelInfoModel.endDate = resultDateStr;
				data.membershipBaseInfoModel.startDate = resultDateStr;
				data.membershipBaseInfoModel.expirationDate = resultDateStr;
			}
		},
		checkIsPreMembership(data) {
			this.isPreMemberhip = data.baseChannelInfoModel.baseChannelModel.code.indexOf('BASE-') == 0 ? false : true; // 기본서비스가 BASE로 시작 X면, 구DOMS 상품 멤버십
		},
		setMembershipStatus(data) {
			this.membershipStatus = data.status;
		},
		setDates(data) {
			let today = new Date();
			let year = new String(today.getFullYear());
			let month = new String(today.getMonth() + 1);
			let day = new String(today.getDate());
			if (month.length == 1) {
				month = '0' + month;
			}
			if (day.length == 1) {
				day = '0' + day;
			}
			const todayStr = year + '-' + month + '-' + day;
			var nextDay = new Date(data.membershipBaseInfoModel.expirationDate); // 2014-03-01 - 월은 0에서부터 시작된다.
			nextDay.setDate(nextDay.getDate() + 1);
			year = new String(nextDay.getFullYear());
			month = new String(nextDay.getMonth() + 1);
			day = new String(nextDay.getDate());
			if (month.length == 1) {
				month = '0' + month;
			}
			if (day.length == 1) {
				day = '0' + day;
			}
			const newStartDayStr = year + '-' + month + '-' + day;
			const param = {
				addStartDate: todayStr,
				addEndDate: data.membershipBaseInfoModel.expirationDate,
				extensionStartDate: newStartDayStr,
			};

			this.$store.commit('SET_DATES', param);
		},
		setUsingMembershipBaseServie(data) {
			this.baseChannelList = [];
			// 현재 사용중인 멤버십 기본서비스를 선택하도록 세팅
			let usingBaseChannel = data.baseChannelInfoModel.baseChannelModel;
			usingBaseChannel['usingCount'] = 1;
			usingBaseChannel['isSelected'] = true;
			usingBaseChannel['defaultFeeItemModel'] = data.baseChannelInfoModel.defaultFeeItemModel;
			usingBaseChannel['addUserItemModels'] = data.baseChannelInfoModel.addUserItemModels;

			this.baseChannelList.push(usingBaseChannel);
		},
		async setUsingBaseInfo() {
			this.$store.commit('CLEAR_APPLY_SERVICE_INFO');
			this.addonChannelList = [];
			// 해당 멤버십에서 선택가능한 부가서비스 리스트 조회
			await this.getAddonChannelList(this.usingMembership.baseChannelInfoModel.baseChannelModel.code);

			// 해당 멤버십에서 선택가능한 기본서비스 옵션 조회
			let optionData = await this.getBaseChannelOptionItemList(
				this.usingMembership.baseChannelInfoModel.baseChannelModel.code,
			);

			this.availableOptionItemsPerService = [];
			if (optionData && optionData.length > 0) {
				let optionPerService = {
					service: this.cloneObj(this.usingMembership.baseChannelInfoModel.baseChannelModel),
					options: optionData,
				};
				optionPerService.service['usingCount'] = 1;
				this.availableOptionItemsPerService.push(optionPerService);
			}

			// 현재 사용중인 부가서비스들의 옵션 조회
			if (this.usingMembership.addonChannelInfoModels.length > 0) {
				await this.usingMembership.addonChannelInfoModels.reduce((previous, current) => {
					return previous.then(async () => {
						if (!(current.addonChannelModel.freeTrialPolicyModel.usePolicy & (current.freeTrialStatus == 'USING'))) {
							let optionData = await this.getAddonChannelOptionItemList(current.addonChannelModel.code, current);
							if (optionData && optionData.length > 0) {
								let optionPerService = {
									service: this.cloneObj(current.addonChannelModel),
									options: optionData,
								};

								this.availableOptionItemsPerService.push(optionPerService);
							}
							// 부가서비스 옵션 조회
						}
					});
				}, Promise.resolve());
			}
		},
	},
};
