export const addUserCountChange =
	// 연장 기간 변경 시
	{
		methods: {
			async checkUserCount() {
				if (this.totUserCnt > this.$constants.MAX_USER_COUNT) {
					this.alert({
						title: '최대 사용자 수 제한',
						contents: '사용자 수는 최대 99999명 까지 신청 가능합니다.',
					});

					this.totUserCnt = this.$constants.MAX_USER_COUNT;
					return;
				}

				// 무료 일반/ 무료 월과금 사용자 추가 시, 유료 전환
				if (
					(this.usingMembership.membershipChargeType === this.$constants.chargeType.FREE ||
						this.usingMembership.membershipChargeType === this.$constants.chargeType.FREE_MONTHLY) &&
					this.totUserCnt > this.usingMembership.totalUserCnt
				) {
					this.alert({
						title: '멤버십 변경',
						contents:
							'사용자 추가시 멤버십이 변경됩니다.  <br />' +
							'클라우드 공유형으로 변경됨에 따라 결제 금액이 발생합니다.  <br />' +
							'멤버십을 변경하시겠습니까?',
						buttons: [
							{
								text: '확인',
								callback: () => {
									this.convertToPayMembership(this.totUserCnt);
								},
							},
							{
								text: '취소',
								callback: () => {
									console.log('close');
									this.totUserCnt = this.usingMembership.totalUserCnt;
								},
							},
						],
					});
					return;
				}

				const data = await this.$store.dispatch('fetchCompanyUsage', this.usingMembership.id);
				if (this.$store.getters.getGovermentSupportBaseChannelCode) {
					if (this.totUserCnt < data.usedUserCount) {
						this.alert({
							title: '최소 사용자 수 제한',
							contents: '사용자 수는 ' + data.usedUserCount + ' 이상이어야 합니다.',
						});
						this.totUserCnt = data.usedUserCount;
						return;
					}
				} else {
					// 무료 일반/ 무료 월과금 -> 유료 -> 사용자 감소 시, 기존 사용하던 멤버십으로 전환
					if (
						this.currentChargeType === this.$constants.chargeType.PAY &&
						(this.originChargeType === this.$constants.chargeType.FREE ||
							this.originChargeType === this.$constants.chargeType.FREE_MONTHLY) &&
						this.totUserCnt <= this.usingMembership.totalUserCnt
					) {
						this.alert({
							title: '멤버십 변경',
							contents: '사용자 감소시 기존 사용하던 멤버십으로 변경됩니다. <br /> 멤버십을 변경하시겠습니까?',
							buttons: [
								{
									text: '확인',
									callback: () => {
										this.convertToOriginMembership();
									},
								},
								{
									text: '취소',
									callback: () => {
										this.totUserCnt = this.usingMembership.totalUserCnt + 1;
									},
								},
							],
						});
						return;
					}

					if (this.totUserCnt < this.existingUserCnt && this.extensionPeriod == 0) {
						this.alert({
							title: '사용자 취소 불가',
							contents: '추가 주문 시, 사용자 수 취소가 불가능합니다.',
						});
						this.totUserCnt = this.existingUserCnt;
						return;
					}

					// 기본 서비스의 기본 인원
					const defaultUserNum =
						this.usingMembership.baseChannelInfoModel.baseChannelModel.defaultFeePolicyModel.defaultUserNum;
					// 기본 서비스의 사용자 추가 기본 인원
					const minimumAddUser =
						this.usingMembership.baseChannelInfoModel.baseChannelModel.addUserIntervalPolicyModel.minimum;
					// 실 사용자 수
					const usedUserCount = data.usedUserCount;
					if (this.totUserCnt < minimumAddUser || this.totUserCnt < defaultUserNum || this.totUserCnt < usedUserCount) {
						const minimumUserNum = Math.max(minimumAddUser, defaultUserNum, usedUserCount);
						this.alert({
							title: '최소 사용자 수 제한',
							contents: '사용자 수는 ' + minimumUserNum + ' 이상이어야 합니다.',
						});

						// 신요금제 전환 대상일 경우, 최소 사용자 수 제한에 걸릴 시 전환 임시 멤버십의 totalUserCnt 로 사용자 수를 리셋한다.
						this.totUserCnt =
							this.$store.getters.isExtensionOrAddExtensionOrderWithConversionProduct || !this.existingUserCnt
								? this.usingMembership.totalUserCnt
								: this.existingUserCnt;
						return;
					}
				}

				// 추가 일 경우
				/* 	1) 사용중 - 기본서비스, 사용중 부가서비스 : 사용자 추가
				2) 신규 추가 부가서비스 : 추가 */

				this.waitUserCount = true;
				await this.$store.commit('SET_ADD_EXTENSION_USER_COUNT', this.totUserCnt);
				if (this.totUserCnt > this.existingUserCnt) {
					// 1-1) 사용중 기본서비스 사용자 추가 계산
					let addBaseItem = this.cloneObj(this.baseChannelList[0]);
					// 현재 사용자 추가 구간모델

					let usingBaseChannlAddUserItem = this.cloneObj(this.usingMembership.baseChannelInfoModel.addUserItemModels);

					if (this.isChangeOrder && addBaseItem.defaultFeePolicyModel.usePolicy) {
						addBaseItem = await this.setDefaultFeeDiscountMatrix(
							addBaseItem,
							this.remainDay, // 잔여기간
							'ADD',
						); // 기본료 계산
					}

					if (this.isChangeOrder && !this.usingMembership.baseChannelInfoModel.paidMembershipFeeDate) {
						// 변경 주문이면서 유료 멤버십 설치비 지불 내역 없을 경우
						addBaseItem = await this.setMembershipFeeDiscountMatrix(addBaseItem);
					}

					addBaseItem = await this.setAddUserDiscountMatrix(
						addBaseItem,
						this.addUserCnt, // 추가 사용자 수
						this.remainDay, // 잔여기간
						'USERADD',
						usingBaseChannlAddUserItem,
					);

					await this.commitWithPayload('SERVICE', 'SET_SERVICE', 'BASE', addBaseItem, 'ADD');
					// 1-2) 사용중 부가서비스 사용자 추가 재 계산
					const usingAddonChannelList = this.addonChannelList.filter((addonChannel) => addonChannel.usingCount > 0);
					if (usingAddonChannelList.length > 0) {
						usingAddonChannelList.reduce((previous, current) => {
							return previous.then(async () => {
								let userAddAddonItem = this.cloneObj(current);
								// 현재 사용중인 추가 구간모델
								const idx = this.usingMembership.addonChannelInfoModels.findIndex(
									(obj) => obj.addonChannelModel.code == userAddAddonItem.code,
								);
								const usingAddonChannlAddUserItem = this.cloneObj(
									this.usingMembership.addonChannelInfoModels[idx].addUserItemModels,
								);

								userAddAddonItem = await this.setAddUserDiscountMatrix(
									userAddAddonItem,
									this.addUserCnt, // 추가 사용자 수
									this.remainDay, // 잔여기간
									'USERADD',
									usingAddonChannlAddUserItem,
								); // 사용자 추가 요금 계산

								await this.commitWithPayload('SERVICE', 'SET_SERVICE', 'ADDON', userAddAddonItem, 'ADD');
							});
						}, Promise.resolve());
					}

					// 2) 신규 추가 부가서비스 (신규 추가 : 잔여기간 * 총 사용자수)
					const newAddAddonChannelList = this.addonChannelList.filter(
						(addonChannel) => addonChannel.visible && addonChannel.isSelected && addonChannel.usingCount == 0,
					);
					if (newAddAddonChannelList.length > 0) {
						newAddAddonChannelList.reduce((previous, current) => {
							return previous.then(async () => {
								let addAddonItem = this.cloneObj(current);

								addAddonItem = await this.setMembershipFeeDiscountMatrix(addAddonItem, 'ADD'); // 설치비 계산

								addAddonItem = await this.setDefaultFeeDiscountMatrix(
									addAddonItem,
									this.remainDay, // 잔여기간
									'ADD',
								); // 기본료 계산

								addAddonItem = await this.setAddUserDiscountMatrix(
									addAddonItem,
									this.totUserCnt, // 총 사용자 수
									this.remainDay, // 잔여기간
									'ADD',
								); // 사용자 추가 요금 계산

								await this.commitWithPayload('SERVICE', 'SET_SERVICE', 'ADDON', addAddonItem, 'ADD');
							});
						}, Promise.resolve());
					}

					// 3-1) 기존사용 기본서비스옵션 && 사용자 fee (신규 추가 : 잔여기간 * 추가 사용자수)

					// 3-2) 신규 추가 기본서비스옵션 (신규 추가 : 잔여기간 * 총 사용자수)

					// 4-1) 기존사용 부가서비스옵션 && 사용자 fee (신규 추가 : 잔여기간 * 추가 사용자수)
					// 4-2) 신규 추가 부가서비스옵션 (신규 추가 : 잔여기간 * 총 사용자수)
				} else if (this.totUserCnt == this.existingUserCnt) {
					// 사용자 추가 빼기

					// 1-1) 사용중 기본서비스 사용자 추가 빼기
					let usingBaseItem = this.cloneObj(this.baseChannelList[0]);
					await this.commitWithPayload('SERVICE', 'REMOVE_SERVICE', 'BASE', usingBaseItem, 'ADD');

					// 1-2) 사용중 부가서비스 사용자 추가 빼기
					const usingAddonChannelList = this.addonChannelList.filter((addonChannel) => addonChannel.usingCount > 0);
					if (usingAddonChannelList.length > 0) {
						usingAddonChannelList.reduce((previous, current) => {
							return previous.then(async () => {
								let userAddAddonItem = this.cloneObj(current);
								await this.commitWithPayload('SERVICE', 'REMOVE_SERVICE', 'ADDON', userAddAddonItem, 'ADD');
							});
						}, Promise.resolve());
					}
				}
				// 연장일 경우
				if (this.extensionPeriod > 0) {
					// 선택된 기본/부가 서비스 = 연장 : 연장기간 * 총 사용자 수

					// 1-1) 기본서비스 연장 재계산
					let extensionBaseItem = this.cloneObj(this.baseChannelList[0]);
					// enddate 계산
					const { data } = await this.$store.dispatch('fetchEndDate', {
						startDate: this.$store.getters.getDates.extensionStartDate,
						period: this.extensionPeriod,
						standardDay: this.usingMembership.endDateStandard,
					});
					const extensionEndDate = data;
					await this.$store.commit('SET_DATES', {
						extensionEndDate: extensionEndDate,
					});
					// 현재 사용자 추가 구간모델
					extensionBaseItem = await this.setDefaultFeeDiscountMatrix(
						extensionBaseItem,
						this.extensionPeriod, // 연장기간
						'EXTENSION',
					); // 기본료 계산
					extensionBaseItem = await this.setAddUserDiscountMatrix(
						extensionBaseItem,
						this.totUserCnt, // 총 사용자 수
						this.extensionPeriod, // 연장기간
						'EXTENSION',
					); // 사용자 추가 요금 계산

					if (
						this.originChargeType === this.$constants.chargeType.FREE &&
						this.currentChargeType === this.$constants.chargeType.PAY &&
						!this.usingMembership.baseChannelInfoModel.paidMembershipFeeDate
					) {
						// 무료 일반 -> 유료 전환이면서 유료 설치비 이력 없을 때
						extensionBaseItem = await this.setMembershipFeeDiscountMatrix(extensionBaseItem);
					}

					await this.commitWithPayload('SERVICE', 'SET_SERVICE', 'BASE', extensionBaseItem, 'EXTENSION');

					// 1-2) 선택한 부가서비스 연장 재계산
					const selectedAddonChannelList = this.addonChannelList.filter((addonChannel) => addonChannel.isSelected);
					if (selectedAddonChannelList.length > 0) {
						selectedAddonChannelList.reduce((previous, current) => {
							return previous.then(async () => {
								let extensionAddonItem = this.cloneObj(current);
								extensionAddonItem = await this.setDefaultFeeDiscountMatrix(
									extensionAddonItem,
									this.extensionPeriod, // 연장기간
									'EXTENSION',
								); // 기본료 계산
								extensionAddonItem = await this.setAddUserDiscountMatrix(
									extensionAddonItem,
									this.totUserCnt, // 총 사용자 수
									this.extensionPeriod, // 연장기간
									'EXTENSION',
								); // 사용자 추가 요금 계산
								await this.commitWithPayload('SERVICE', 'SET_SERVICE', 'ADDON', extensionAddonItem, 'EXTENSION');
							});
						}, Promise.resolve());
					}
				}
				// 옵션상품 재계산
				await this.availableOptionItemsPerService.reduce((previousService, optionPerService) => {
					return previousService.then(async () => {
						let serviceType = '';
						let freeTrialPolicyModel = null;
						let isSelected = true;
						if (optionPerService.service.code.indexOf('ADDON-') > -1) {
							serviceType = 'ADDON';
							freeTrialPolicyModel = optionPerService.service.freeTrialPolicyModel;
							isSelected = await this.isSelectedAddon(optionPerService.service.code);
						} else {
							serviceType = 'BASE';
						}
						const service = {
							name: optionPerService.service.name,
							code: optionPerService.service.code,
							serviceCategoryModel: optionPerService.service.serviceCategoryModel,
							defaultFeePolicyModel: optionPerService.service.defaultFeePolicyModel,
							addUserIntervalPolicyModel: optionPerService.service.addUserIntervalPolicyModel,
							membershipFeePolicyModel: optionPerService.service.membershipFeePolicyModel,
							freeTrialPolicyModel: freeTrialPolicyModel,
							chargeType: optionPerService.service.chargeType,
							termsSectionModel: optionPerService.service.termsSectionModel,
							usingCount: 1,
							isSelected: isSelected,
						};
						await optionPerService.options.reduce((previousOption, option) => {
							return previousOption.then(async () => {
								await option.items.reduce((previousItem, item) => {
									return previousItem.then(async () => {
										//유료상품 기본서비스 수대로 과금일 경우, 옵션수량을 현재 사용자수로 수정한다.
										if (this.isChangeOrderCount(item)) {
											item.orderCount = this.totUserCnt;
											item.gapCount = this.totUserCnt - item.usingCount;
										}
										await this.addUpdateOptionByCase(item, serviceType, service);
									});
								}, Promise.resolve());
							});
						}, Promise.resolve());
					});
				}, Promise.resolve());
				this.waitUserCount = false;
			},
			isChangeOrderCount(item) {
				return (
					(item.orderCount > 0 || item.channelItemSalesSectionModel.defaultSelected) &&
					item.channelItemSalesSectionModel.channelItemSalesPolicyModel.dependsOnUserCount &&
					!item.channelItemSalesSectionModel.noCharge
				);
			},
		},
		watch: {
			// 사용자 수 변경 시
			addUserCnt() {
				if (this.$store.getters.getTotalUserCountFocus) {
					return;
				}

				this.checkUserCount();
			},
		},
	};
