var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visibleAddonServices.length)?_c('div',{staticClass:"group column additional-service"},[_vm._m(0),_c('div',{staticClass:"form group row wrap"},[_vm._l((_vm.visibleAddonServices),function(item,index){return [(item.visible)?_c('div',{key:index,staticClass:"card",class:{
					active: item.isSelected,
				},on:{"click":function($event){return _vm.canReduceItem(item, _vm.extensionPeriod)}}},[_c('div',{staticClass:"group-chk"},[_c('input',{attrs:{"disabled":_vm.isDisabledAddon(item),"type":"checkbox"},domProps:{"checked":item.isSelected},on:{"click":function($event){return _vm.selectAddonService(item)}}}),_c('label',{staticClass:"label"},[_c('div',{staticClass:"service-card"},[_c('h4',[(_vm.getItemClass(item))?_c('i',{staticClass:"ic-24",class:_vm.getItemClass(item)}):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]),_c('table',{},[_c('tbody',[_c('tr',[_vm._m(1,true),_c('td',{},[_c('span',{},[_vm._v(_vm._s(_vm._f("currency")(item.defaultFeePolicyModel.price))+"원 "+_vm._s(_vm._f("billingTypeDesc")(item.defaultFeePolicyModel.billingType)))])])]),_c('tr',[_vm._m(2,true),_c('td',{},[_c('span',{},[_vm._v(_vm._s(_vm._f("currency")(item.membershipFeePolicyModel.price))+"원 "+_vm._s(_vm._f("billingTypeDesc")(item.membershipFeePolicyModel.billingType)))])])]),_c('tr',[_vm._m(3,true),_c('td',{},[_c('span',{},[_vm._v(_vm._s(_vm._f("currency")(item.addUserIntervalPolicyModel.price))+"원 / 인 "+_vm._s(_vm._f("billingTypeDesc")(item.addUserIntervalPolicyModel.billingType)))])])])])])])])])]):_vm._e()]})],2)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"definition label"},[_c('span',{},[_vm._v(" 부가 서비스 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{},[_c('span',{},[_vm._v("기본료")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{},[_c('span',{},[_vm._v("설치비")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{},[_c('span',{},[_vm._v("사용자")])])
}]

export { render, staticRenderFns }