<template>
	<div class="section-aside" :class="{ open: openSideBar }">
		<!-- 추가/연장 사이드바 헤더 -->
		<div>
			<div
				class="aside-header"
				v-if="this.$store.getters.getUsingMembership !== null"
				@click="
					() => {
						this.openSideBar = '';
					}
				"
			>
				<!-- 사용중인 membership 정보 헤더 출력 -->
				<p class="description">결제 금액</p>
				<h3 class="heading-2-b">
					{{ serviceNameDisplay(this.$store.getters.getUsingMembership.baseChannelInfoModel.baseChannelModel) }}
				</h3>
				<ul class="description">
					<li class="txt">멤버 {{ this.$store.getters.getAddExtensionUserCnt }}인</li>
				</ul>
			</div>
			<div
				class="contents-wrap"
				v-if="this.$store.getters.getBaseChannel.length || this.$store.getters.getAddonChannel.length"
			>
				<div class="contents" v-if="this.$store.getters.getBaseChannel.length">
					<table class="summary">
						<!-- 기본 서비스 -->
						<tbody v-for="item in this.$store.getters.getBaseChannel" :key="item.baseInfo.code">
							<!-- 기본 서비스 연장 정보 -->
							<template v-for="extension in item.extension">
								<tr
									:key="'base_membershipFee_extension_' + extension.code"
									v-if="!paidMembershipFeeDate && extension.membershipFeePolicyModel.usePolicy"
								>
									<td class="tit"><span class="txt">설치비</span></td>
									<td class="amount">&nbsp;</td>
									<td class="price">
										<span class="txt">{{ extension.membershipFeeDiscountPrice | currency }}원</span>
										<span
											class=""
											v-if="extension.membershipFeeDiscount < 0"
											v-on:click="toggleFold('base', 'ADD', 'membershipFee', extension.code)"
										>
											<button v-if="extension.membershipFeeDetailFold == false" class="icon" title="접기">
												<i class="ic-16 ic-fold"></i>
											</button>
											<button v-if="extension.membershipFeeDetailFold == true" class="icon" title="펼치기">
												<i class="ic-16 ic-unfold"></i>
											</button>
										</span>
									</td>
								</tr>
								<tr
									:key="'base_defaultFee_extension_' + extension.code"
									v-if="extension.defaultFeePolicyModel.usePolicy"
								>
									<td class="tit">
										<span class="txt">{{ extension.usePeriod }}개월 {{ extensionDesc }} 기본료</span>
									</td>
									<td class="amount">
										<span class="txt"
											>{{ extension.defaultFeePolicyModel.defaultUserNum }}명/{{
												extension.defaultFeePolicyModel.defaultVolume
											}}GB</span
										>
									</td>
									<td class="price">
										<span class="txt">{{ extension.defaultFeeTotDiscountPrice | currency }}원</span>
										<span
											class=""
											v-if="extension.defaultFeeTotDiscount < 0"
											v-on:click="toggleFold('base', 'EXTENSION', 'defaultFee', extension.code)"
										>
											<button v-if="extension.defaultFeeDetailFold == false" class="icon" title="접기">
												<i class="ic-16 ic-fold"></i>
											</button>
											<button v-if="extension.defaultFeeDetailFold == true" class="icon" title="펼치기">
												<i class="ic-16 ic-unfold"></i>
											</button>
										</span>
									</td>
								</tr>
								<tr
									v-if="extension.defaultFeeDetailFold == false"
									:key="'base_defaultFee_extension_discount_' + extension.code"
								>
									<td colspan="3">
										<div class="highlight">
											<table class="">
												<tbody>
													<tr>
														<td class="tit">
															<span class="txt">단가</span>
														</td>
														<td class="price">
															<span class="txt">{{ extension.defaultFeePolicyModel.price | currency }}원</span>
														</td>
													</tr>
													<tr>
														<td class="tit">
															<span class="txt">사용기간</span>
														</td>
														<td class="price">
															<span class="txt">{{ extension.usePeriod }} 개월 </span>
														</td>
													</tr>
													<tr>
														<td class="tit">
															<span class="txt">정가</span>
														</td>
														<td class="price">
															<span class="txt">{{ extension.defaultFeeTotNormalPrice | currency }}원</span>
														</td>
													</tr>
													<tr class="discount">
														<td class="tit">
															<span class="txt">할인 금액</span>
														</td>
														<td class="price">
															<span class="txt">{{ extension.defaultFeeTotDiscount | currency }}원</span>
														</td>
													</tr>
													<tr class="balance">
														<td class="tit">
															<span class="txt">최종 금액</span>
														</td>
														<td class="price">
															<span class="txt">{{ extension.defaultFeeTotDiscountPrice | currency }}원</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>
								<tr
									:key="'base_addUserFee_extension_' + extension.code"
									v-if="extension.addUserCnt > 0 && extension.addUserIntervalPolicyModel.usePolicy"
								>
									<td class="tit">
										<span class="txt">사용자{{ extensionDesc }}</span>
									</td>
									<td class="amount">
										<span class="txt"
											>{{ extension.addUserCnt }}명
											<template
												v-if="
													getTotQuota(
														extension.addUserCnt,
														extension.addUserIntervalPolicyModel.defaultVolumeForAddUser,
														'EXTENSION',
													) > 0
												"
												>/{{
													getTotQuota(
														extension.addUserCnt,
														extension.addUserIntervalPolicyModel.defaultVolumeForAddUser,
														'EXTENSION',
													)
												}}GB
											</template></span
										>
									</td>
									<td class="price">
										<span class="txt">{{ extension.addUserTotDiscountPrice | currency }}원</span>
									</td>
								</tr>
								<tr
									:key="'base_addUserFee_extension1_' + extension.code"
									v-if="
										extension.addUserCnt > 0 &&
										extension.addUserIntervalPolicyModel.usePolicy &&
										extension.addUserTotNormalPrice != 0
									"
									class="mini"
								>
									<td class="tit"></td>
									<td class="amount"></td>
									<td class="price">
										<span class="txt">
											<del class="text-through">{{ extension.addUserTotNormalPrice | currency }}원</del>
										</span>
										<button class="line round x-mini" v-on:click="openModal(extension)">상세</button>
									</td>
								</tr>
							</template>
							<!-- 기본서비스 추가 정보 -->
							<template v-for="add in item.add">
								<tr
									:key="'base_defaultFee_add_' + add.code"
									v-if="add.defaultFeeTotDiscountPrice && add.defaultFeePolicyModel.usePolicy"
								>
									<td class="tit">
										<span class="txt">{{ getRemainDay() }} {{ addDesc }} 기본료</span>
									</td>
									<td class="amount">
										<span class="txt"
											>{{ add.defaultFeePolicyModel.defaultUserNum }}명/{{
												add.defaultFeePolicyModel.defaultVolume
											}}GB</span
										>
									</td>
									<td class="price">
										<span class="txt">{{ add.defaultFeeTotDiscountPrice }}원</span>
										<span
											class=""
											v-if="add.defaultFeeTotDiscount < 0"
											v-on:click="toggleFold('base', 'ADD', 'defaultFee', add.code)"
										>
											<button v-if="add.defaultFeeDetailFold == false" class="icon" title="접기">
												<i class="ic-16 ic-fold"></i>
											</button>
											<button v-if="add.defaultFeeDetailFold == true" class="icon" title="펼치기">
												<i class="ic-16 ic-unfold"></i>
											</button>
										</span>
									</td>
								</tr>
								<tr :key="'base_defaultFee_add_discount_' + add.code" v-if="add.defaultFeeDetailFold == false">
									<td colspan="3">
										<table class="">
											<tbody>
												<tr>
													<td class="tit">
														<span class="txt">단가</span>
													</td>
													<td class="price">
														<span class="txt">{{ add.defaultFeePolicyModel.price | currency }}원</span>
													</td>
												</tr>
												<tr>
													<td class="tit">
														<span class="txt">사용기간</span>
													</td>
													<td class="price">
														<span class="txt">{{ add.usePeriod }} 개월 </span>
													</td>
												</tr>
												<tr>
													<td class="tit">
														<span class="txt">정가</span>
													</td>
													<td class="price">
														<span class="txt">{{ add.defaultFeeTotNormalPrice | currency }}원</span>
													</td>
												</tr>
												<tr class="discount">
													<td class="tit">
														<span class="txt">할인 금액</span>
													</td>
													<td class="price">
														<span class="txt">{{ add.defaultFeeTotDiscount | currency }}원</span>
													</td>
												</tr>
												<tr class="balance">
													<td class="tit">
														<span class="txt">최종 금액</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ add.defaultFeeTotDiscountPrice | currency }}원</span>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
								<tr
									:key="'base_addUserFee_add_' + add.code"
									v-if="add.addUserCnt && add.addUserIntervalPolicyModel.usePolicy"
								>
									<td class="tit">
										<span class="txt">사용자 {{ addDesc }}</span>
									</td>
									<td class="amount">
										<span class="txt"
											>{{ add.addUserCnt }}명
											<template
												v-if="
													getTotQuota(add.addUserCnt, add.addUserIntervalPolicyModel.defaultVolumeForAddUser, 'ADD') > 0
												"
												>/{{
													getTotQuota(add.addUserCnt, add.addUserIntervalPolicyModel.defaultVolumeForAddUser, 'ADD')
												}}GB
											</template>
										</span>
									</td>
									<td class="price">
										<span class="txt">{{ add.addUserTotDiscountPrice | currency }}원</span>
									</td>
								</tr>
								<tr
									:key="'base_addUserFee_add1_' + add.code"
									v-if="add.addUserCnt && add.addUserIntervalPolicyModel.usePolicy && add.addUserTotNormalPrice != 0"
									class="mini"
								>
									<td class="tit"></td>
									<td class="amount"></td>
									<td class="price">
										<span class="txt"
											><del class="text-through">{{ add.addUserTotNormalPrice | currency }}원</del></span
										><button class="line round x-mini" v-on:click="openModal(add)">상세</button>
									</td>
								</tr>
								<tr
									:key="'base_membershipFee_add_' + add.code"
									v-if="add.membershipFeeDiscountPrice && add.membershipFeePolicyModel.usePolicy"
								>
									<td class="tit"><span class="txt">설치비</span></td>
									<td class="amount">&nbsp;</td>
									<td class="price">
										<span class="txt">{{ add.membershipFeeDiscountPrice | currency }}원</span>
										<span
											class=""
											v-if="add.membershipFeeDiscount < 0"
											v-on:click="toggleFold('base', 'ADD', 'membershipFee', add.code)"
										>
											<button v-if="add.membershipFeeDetailFold == false" class="icon" title="접기">
												<i class="ic-16 ic-fold"></i>
											</button>
											<button v-if="add.membershipFeeDetailFold == true" class="icon" title="펼치기">
												<i class="ic-16 ic-unfold"></i>
											</button>
										</span>
									</td>
								</tr>
								<tr :key="'base_membershipFee_add_discount_' + add.code" v-if="add.membershipFeeDetailFold == false">
									<td colspan="3">
										<div class="highlight">
											<table class="">
												<tbody>
													<tr>
														<td class="tit">
															<span class="txt">정가</span>
														</td>
														<td class="price">
															<span class="txt">{{ add.membershipFeeNormalPrice | currency }}원</span>
														</td>
													</tr>
													<tr class="discount">
														<td class="tit">
															<span class="txt">할인 금액</span>
														</td>
														<td class="price">
															<span class="txt">{{ add.membershipFeeDiscount | currency }}원</span>
														</td>
													</tr>
													<tr class="balance">
														<td class="tit">
															<span class="txt">최종 금액</span>
														</td>
														<td class="price">
															<span class="txt">{{ add.membershipFeeDiscountPrice | currency }}원</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>
							</template>
							<!-- 기본서비스 옵션 -->
							<template v-for="(option, index) in item.option">
								<tr
									v-for="extension in option.extension"
									:key="'base_option_extension_' + extension.code + '_' + index"
								>
									<td class="tit">
										<span class="txt">{{ extension.displaySectionModel.displayName }} {{ extensionDesc }}</span>
									</td>
									<td class="amount">
										<span class="txt">{{ extension.orderCount }}</span>
									</td>
									<td class="price">
										<span class="txt">{{ extension.finalPrice | currency }}원</span>
									</td>
								</tr>
								<tr v-for="add in option.add" :key="'base_option_add_' + add.code + '_' + index">
									<td class="tit">
										<span class="txt">{{ add.displaySectionModel.displayName }} {{ addDesc }}</span>
									</td>
									<td class="amount">
										<span class="txt">{{ add.orderCount }}</span>
									</td>
									<td class="price">
										<span class="txt">{{ add.finalPrice | currency }}원</span>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
				<!-- 기본서비스 끝 -->

				<!-- 부가서비스 시작-->
				<div class="contents" v-for="(item, index) in this.$store.getters.getAddonChannel" :key="index">
					<h4>
						<!-- 헤더 -->
						<i v-if="item.baseInfo.name.indexOf('경리회계') > -1" class="ic-24 ic-accounting"></i>
						<i v-if="item.baseInfo.name == '경비처리(무증빙)'" class="ic-24 ic-bizplay"></i>
						<i v-if="item.baseInfo.name == 'TMSe 장비일체형 S'" class="ic-24 ic-tmse_s"></i>
						<i v-if="item.baseInfo.name == 'TMSe 장비일체형 E'" class="ic-24 ic-tmse_e"></i>
						<i v-if="item.baseInfo.name == 'TMSe 장비일체형 T'" class="ic-24 ic-tmse_t"></i>
						{{ item.baseInfo.name }}
						<!-- <span v-if="item.baseInfo.freeTrialPolicyModel.usePolicy" class="com_tag_badge">
							<span class="txt" v-if="item.baseInfo.usingCount > 0">유료 전환 완료</span>
							<span class="txt" v-if="item.baseInfo.usingCount == 0"
								>{{ item.baseInfo.freeTrialPolicyModel.usagePeriod }}개월 무료 체험</span
							>
						</span> -->
					</h4>

					<!-- 부가서비스 연장 정보 -->
					<table class="summary">
						<tbody>
							<template v-for="(extension, index) in item.extension">
								<tr :key="'addon_defaltFee_extension_' + index" v-if="extension.defaultFeePolicyModel.usePolicy">
									<td class="tit">
										<span class="txt">{{ extension.usePeriod }}개월{{ extensionDesc }} 기본료</span>
									</td>
									<td class="amount">
										<span class="txt">{{ extension.defaultFeePolicyModel.defaultUserNum }}</span>
									</td>
									<td class="price">
										<span class="txt">{{ extension.defaultFeeTotDiscountPrice | currency }}원</span>
										<span
											class=""
											v-if="extension.defaultFeeTotDiscount < 0"
											v-on:click="toggleFold('addon', 'EXTENSION', 'defaultFee', extension.code)"
										>
											<button v-if="extension.defaultFeeDetailFold == false" class="icon" title="접기">
												<i class="ic-16 ic-fold"></i>
											</button>
											<button v-if="extension.defaultFeeDetailFold == true" class="icon" title="펼치기">
												<i class="ic-16 ic-unfold"></i>
											</button>
										</span>
									</td>
								</tr>
								<tr :key="'addon_defaltFee_extension_discount_' + index" v-if="extension.defaultFeeDetailFold == false">
									<td colspan="3">
										<div class="highlight">
											<table class="">
												<tbody>
													<tr>
														<td class="tit">
															<span class="txt">단가</span>
														</td>
														<td class="price">
															<span class="txt">{{ extension.defaultFeePolicyModel.price | currency }}원</span>
														</td>
													</tr>
													<tr>
														<td class="tit">
															<span class="txt">사용기간</span>
														</td>
														<td class="price">
															<span class="txt">{{ extension.usePeriod }} 개월 </span>
														</td>
													</tr>
													<tr>
														<td class="tit">
															<span class="txt">정가</span>
														</td>
														<td class="price">
															<span class="txt">{{ extension.defaultFeeTotNormalPrice | currency }}원</span>
														</td>
													</tr>
													<tr class="discount">
														<td class="tit">
															<span class="txt">할인 금액</span>
														</td>
														<td class="price">
															<span class="txt">{{ extension.defaultFeeTotDiscount | currency }}원</span>
														</td>
													</tr>
													<tr class="balance">
														<td class="tit">
															<span class="txt">최종 금액</span>
														</td>
														<td class="price">
															<span class="txt">{{ extension.defaultFeeTotDiscountPrice | currency }}원</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>
								<tr
									:key="'addon_addUserFee_extension_' + index"
									v-if="extension.addUserIntervalPolicyModel.usePolicy && extension.addUserCnt > 0"
								>
									<td class="tit">
										<span class="txt">사용자{{ extensionDesc }}</span>
									</td>
									<td class="amount">
										<span class="txt">{{ extension.addUserCnt }}</span>
									</td>
									<td class="price">
										<span class="txt">{{ extension.addUserTotDiscountPrice | currency }}원</span>
									</td>
								</tr>
								<tr
									:key="'addon_addUserFee_extension_' + index"
									v-if="
										extension.addUserIntervalPolicyModel.usePolicy &&
										extension.addUserCnt > 0 &&
										extension.addUserTotNormalPrice != 0
									"
									class="mini"
								>
									<td class="tit"></td>
									<td class="amount"></td>
									<td class="price">
										<span class="txt"
											><del class="text-through">{{ extension.addUserTotNormalPrice | currency }}원</del></span
										><button
											class="line round x-mini"
											v-if="!extension.freeTrialPolicyModel.usePolicy"
											v-on:click="openModal(extension)"
										>
											상세
										</button>
									</td>
								</tr>
							</template>
							<!-- 부가서비스 추가(신규추가/사용자추가) 정보-->
							<template v-for="(add, index) in item.add">
								<tr
									:key="'addon_defaltFee_add_' + index"
									v-if="
										add.defaultFeePolicyModel.usePolicy &&
										add.defaultFeeTotDiscountPrice !== undefined &&
										add.defaultFeeTotDiscountPrice !== null &&
										add.defaultFeeTotDiscountPrice !== ''
									"
								>
									<td class="tit">
										<span class="txt">{{ addDesc }} 기본료</span>
									</td>
									<td class="amount">
										<span class="txt">{{ add.defaultFeePolicyModel.defaultUserNum }}</span>
									</td>
									<td class="price">
										<span class="txt">{{ add.defaultFeeTotDiscountPrice | currency }}원</span>
										<span
											class=""
											v-if="add.defaultFeeTotDiscount < 0"
											v-on:click="toggleFold('addon', 'ADD', 'defaultFee', add.code)"
										>
											<button v-if="add.defaultFeeDetailFold == false" class="icon" title="접기">
												<i class="ic-16 ic-fold"></i>
											</button>
											<button v-if="add.defaultFeeDetailFold == true" class="icon" title="펼치기">
												<i class="ic-16 ic-unfold"></i>
											</button>
										</span>
									</td>
								</tr>
								<tr :key="'addon_defaltFee_add_discount_' + index" v-if="add.defaultFeeDetailFold == false">
									<td colspan="3">
										<div class="highlight">
											<table class="">
												<tbody>
													<tr>
														<td class="tit">
															<span class="txt">단가</span>
														</td>
														<td class="price">
															<span class="txt">{{ add.defaultFeePolicyModel.price | currency }}원</span>
														</td>
													</tr>
													<tr>
														<td class="tit">
															<span class="txt">사용기간</span>
														</td>
														<td class="price">
															<span class="txt">{{ add.usePeriod.remainMonth }} 개월 </span>
															<span class="txt">{{ add.usePeriod.remainDay }} 일 </span>
														</td>
													</tr>
													<tr>
														<td class="tit">
															<span class="txt">정가</span>
														</td>
														<td class="price">
															<span class="txt">{{ add.defaultFeeTotNormalPrice | currency }}원</span>
														</td>
													</tr>
													<tr class="discount">
														<td class="tit">
															<span class="txt">할인 금액</span>
														</td>
														<td class="price">
															<span class="txt">{{ add.defaultFeeTotDiscount | currency }}원</span>
														</td>
													</tr>
													<tr class="balance">
														<td class="tit">
															<span class="txt">최종 금액</span>
														</td>
														<td class="amount"></td>
														<td class="price">
															<span class="txt">{{ add.defaultFeeTotDiscountPrice | currency }}원</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>
								<tr
									:key="'addon_addUserFee_add_' + index"
									v-if="
										add.addUserIntervalPolicyModel.usePolicy &&
										add.addUserCnt !== undefined &&
										add.addUserCnt !== null &&
										add.addUserCnt !== '' &&
										add.addUserCnt > 0
									"
								>
									<td class="tit">
										<span class="txt">사용자 {{ addDesc }}</span>
									</td>
									<td class="amount">
										<span class="txt">{{ add.addUserCnt }}</span>
									</td>
									<td class="price">
										<span class="txt">{{ add.addUserTotDiscountPrice | currency }}원</span>
									</td>
								</tr>
								<tr
									:key="'addon_addUserFee_add2_' + index"
									v-if="
										add.addUserIntervalPolicyModel.usePolicy &&
										add.addUserCnt !== undefined &&
										add.addUserCnt !== null &&
										add.addUserCnt !== '' &&
										add.addUserCnt > 0 &&
										add.addUserTotNormalPrice != 0
									"
									class="mini"
								>
									<td class="tit"></td>
									<td class="amount"></td>
									<td class="price">
										<span class="txt"
											><del class="text-through">{{ add.addUserTotNormalPrice | currency }}원</del></span
										><button
											class="line round x-mini"
											v-if="!add.freeTrialPolicyModel.usePolicy"
											v-on:click="openModal(add)"
										>
											상세
										</button>
									</td>
								</tr>
								<tr
									:key="'addon_membershipFee_add_' + index"
									v-if="
										add.membershipFeeDiscountPrice !== undefined &&
										add.membershipFeeDiscountPrice !== null &&
										add.membershipFeeDiscountPrice !== ''
									"
								>
									<td class="tit"><span class="txt">설치비</span></td>
									<td class="amount">&nbsp;</td>
									<td class="price">
										<span class="txt">{{ add.membershipFeeDiscountPrice | currency }}원</span>
										<span
											class=""
											v-if="add.membershipFeeDiscount < 0"
											v-on:click="toggleFold('addon', 'ADD', 'membershipFee', add.code)"
										>
											<button v-if="add.membershipFeeDetailFold == false" class="icon" title="접기">
												<i class="ic-16 ic-fold"></i>
											</button>
											<button v-if="add.membershipFeeDetailFold == true" class="icon" title="펼치기">
												<i class="ic-16 ic-unfold"></i>
											</button>
										</span>
									</td>
								</tr>
								<tr :key="'addon_membershipFee_add_discount_' + index" v-if="add.membershipFeeDetailFold == false">
									<td colspan="3">
										<div class="highlight">
											<table class="">
												<tbody>
													<tr>
														<td class="tit">
															<span class="txt">정가</span>
														</td>
														<td class="price">
															<span class="txt">{{ add.membershipFeeNormalPrice | currency }}원</span>
														</td>
													</tr>
													<tr class="discount">
														<td class="tit">
															<span class="txt">할인 금액</span>
														</td>
														<td class="price">
															<span class="txt">{{ add.membershipFeeDiscount | currency }}원</span>
														</td>
													</tr>
													<tr class="balance">
														<td class="tit">
															<span class="txt">최종 금액</span>
														</td>
														<td class="price">
															<span class="txt">{{ add.membershipFeeDiscountPrice | currency }}원</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>
							</template>
							<!-- 옵션상품 -->
							<template v-for="(option, index) in item.option">
								<tr
									v-for="extension in option.extension"
									:key="'addon_option_extension_' + index + '_' + extension.code"
								>
									<td class="tit">
										<span class="txt">{{ extension.displaySectionModel.displayName }} {{ extensionDesc }}</span>
									</td>
									<td class="amount">
										<span class="txt">{{ extension.orderCount }}</span>
									</td>
									<td class="price">
										<span class="txt">{{ extension.finalPrice | currency }}원</span>
									</td>
								</tr>
								<tr v-for="add in option.add" :key="'addon_option_add_' + index + '_' + add.code">
									<td class="tit">
										<span class="txt">{{ add.displaySectionModel.displayName }} {{ addDesc }}</span>
									</td>
									<td class="amount">
										<span class="txt">{{ add.orderCount }}</span>
									</td>
									<td class="price">
										<span class="txt">{{ add.finalPrice | currency }}원</span>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>

			<!--금액 합계-->
			<div class="aside-footer">
				<div class="contents">
					<table class="summary">
						<tbody>
							<tr class="total">
								<td class="tit"><span class="txt">상품 금액</span></td>
								<td class="price">
									<span class="txt">{{ this.$store.getters.getAddExtensionPrices.totOriginPrice | currency }}원</span>
								</td>
							</tr>
							<tr class="discount">
								<td class="tit">
									<span class="txt">할인 금액</span>
									<span class="">
										<span class="ic-16 ic-info">
											<span class="tooltip-layer right">
												12개월 이상 선납 할인 또는 프로모션으로 혜택 받은 금액입니다. <i class="tail_right"></i
											></span>
										</span>
									</span>
								</td>
								<td class="price">
									<span class="txt">{{ this.$store.getters.getAddExtensionPrices.totDiscountPrice | currency }}원</span>
								</td>
							</tr>
							<tr>
								<td class="tit">
									<span class="txt" v-if="currentFixedOrderType === 'CHANGE'">멤버십전환금액</span>
									<span class="txt" v-else>상품 금액 - 할인 금액</span>
								</td>
								<td class="price">
									<span class="txt"
										>{{
											($store.getters.getAddExtensionPrices.totOriginPrice +
												$store.getters.getAddExtensionPrices.totDiscountPrice)
												| currency
										}}원</span
									>
								</td>
							</tr>
							<template v-if="currentFixedOrderType === 'CHANGE'">
								<tr class="discount">
									<td class="tit">
										<span class="txt"
											>잔여금액
											<span class="">
												<i class="ic-16 ic-info">
													<span class="tooltip-layer right">
														기존 결제금액에서 현재까지 사용료를 제외한 금액입니다.
													</span>
												</i>
											</span></span
										>
									</td>

									<td class="price">
										<span class="txt">{{ (-1 * this.$store.getters.getRefundableAmount) | currency }}원</span>
									</td>
								</tr>
								<tr>
									<td class="tit">
										<span class="txt">멤버십전환금액 - 잔여금액</span>
									</td>
									<td class="price">
										<span class="txt"
											>{{
												$store.getters.getAddExtensionPrices.totPayPrice > 0
													? $store.getters.getAddExtensionPrices.totPayPrice
													: 0 | currency
											}}원</span
										>
									</td>
								</tr>
							</template>
							<tr>
								<td class="tit"><span class="txt">부가세(10%)</span></td>
								<td class="price">
									<span class="txt">{{ this.$store.getters.getAddExtensionPrices.surtaxPrice | currency }}원</span>
								</td>
							</tr>
							<tr
								class="balance align-middle"
								@click="
									() => {
										this.openSideBar = 'open';
									}
								"
							>
								<td class="tit">
									<span class="txt">총 금액</span>
									<em>(VAT포함)</em>
								</td>
								<td class="price">
									<span class="txt price"
										>{{ this.$store.getters.getAddExtensionPrices.finalPayPrice | currency }}<em>원</em></span
									>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="btn-area">
					<template v-if="type === 'ADDEXTENSION'">
						<div v-if="this.$store.getters.isSaaSNotReseller">
							<button class="solid full primary" v-on:click="purchaseAddExtension()">
								<span class="txt">{{ usePayMethod ? '결제하기' : '서비스 신청' }}</span>
							</button>
						</div>
						<div v-else class="solid full primary">
							<div class="additional-info">
								<i class="ic-24 ic-notice"></i>
								<p>
									파트너(리셀러)를 통해 계약한 고객사와 단독설치구축형은 온라인 주문이 불가능합니다. 추가/연장주문이
									필요하실 경우 영업담당자에게 연락해주시기 바랍니다.
									<br />
									상담문의 1599-9460
								</p>
							</div>
						</div>
					</template>
				</div>
				<div
					class="additional-info"
					v-if="this.$store.getters.getUsingMembership !== null && getServiceType() === 'VPC'"
				>
					<i class="ic-24 ic-notice"></i>
					<p>
						단독형은 온라인 주문이 불가능합니다. <br />
						추가/연장 주문이 필요하실 경우 다우오피스 영업 담당자에게 문의하시기 바랍니다.
						<br />
						1577-3019(내선 2번)
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { product } from '@/views/mixinsProduct.js';
import mixinsAlert from '@/components/common/mixinsAlert';

export default {
	mixins: [product, mixinsAlert],
	data() {
		return {
			baseFoldFlag: {},
			addonFoldFlag: {},
			columns: ['구간', '가격', '할인수', '할인', '할인 금액'],
			top: '50px',
			showModal: false,
			totalAddUserVolume: 0,
			totalBaseUserVolume: 0,
			totalCompanyUsage: 0,
			totalOrderStorage: 0,
			openSideBar: '',
		};
	},
	props: {
		type: {
			type: String,
			default: 'NEW',
		},
	},
	computed: {
		paidMembershipFeeDate() {
			return this.$store.getters.getUsingMembership.baseChannelInfoModel.paidMembershipFeeDate;
		},
		usePayMethod() {
			return this.$store.getters.getAddExtensionPrices.finalPayPrice === 0 ? false : true;
		},
		currentFixedOrderType() {
			return this.$store.getters.getFixedOrderType;
		},
		addDesc() {
			return this.$store.getters.getFixedOrderType === 'CHANGE' ? '' : '추가';
		},
		extensionDesc() {
			return this.$store.getters.getFixedOrderType === 'EXTENSION' ? '' : ' 연장';
		},
	},
	methods: {
		getServiceType() {
			return this.$store.getters.getUsingMembership.serviceType
				? this.$store.getters.getUsingMembership.serviceType
				: this.$store.getters.getUsingMembership.baseChannelInfoModel.baseChannelModel.serviceCategoryModel.serviceType;
		},
		getRemainDay() {
			const remainDay = this.$store.getters.getRemainDay;

			const strRemainDay =
				(remainDay.remainMonth > 0 ? remainDay.remainMonth + '개월' : '') +
				(remainDay.remainMonth > 0 && remainDay.remainDay > 0 ? ' ' : '') +
				(remainDay.remainDay > 0 ? remainDay.remainDay + '일' : '');

			return this.$store.getters.getFixedOrderType === 'CHANGE' ? strRemainDay : '';
		},
		getTotQuota(addUserCnt, unitQuota, type) {
			// addUserItemModel 이 구간별로 존재할 경우 (ex 1~50: 50명, 51~100: 30명), 각 구간의 usingCount 를 합산한다.
			const addUserItemModels = this.$store.getters.getUsingMembership.baseChannelInfoModel.addUserItemModels;
			let baseAddAccountUsingCount = 0;
			if (addUserItemModels) {
				addUserItemModels.forEach((item) => {
					baseAddAccountUsingCount += item.usingCount;
				});
			}

			// 총용량 계산 return
			if (!addUserCnt) {
				return 0;
			}
			if (!unitQuota) {
				return 0;
			}

			// console.log(`addUserCnt: ${addUserCnt}, type: ${type}, baseAddAccountUsingCount: ${baseAddAccountUsingCount}`);
			if (baseAddAccountUsingCount === addUserCnt) {
				this.totalAddUserVolume = 0;
			} else {
				if (type === 'EXTENSION') {
					this.totalAddUserVolume = parseInt(addUserCnt - baseAddAccountUsingCount) * parseInt(unitQuota);
				} else if (type === 'ADD') {
					this.totalAddUserVolume = parseInt(addUserCnt) * parseInt(unitQuota);
				}
			}
			return parseInt(addUserCnt) * parseInt(unitQuota);
		},
		// 현재 서비스에 할당된 용량
		setCurrentUsage() {
			const usingMembership = this.$store.getters.getUsingMembership;
			this.totalBaseUserVolume = usingMembership.baseChannelInfoModel.totalQuota;
		},
		getTotalStorage() {
			const usingMembership = this.$store.getters.getUsingMembership;

			let sumChannelItems = 0;
			usingMembership.baseChannelInfoModel.usingChannelItemModels.forEach((item) => {
				if (item.channelItemType === 'VOLUME') {
					sumChannelItems += parseInt(item.channelItemModel.itemValue) * item.usingCount;
				}
			});
			console.log('totalQuota: ', usingMembership.baseChannelInfoModel.totalQuota);
			console.log('sumChannelItems: ', sumChannelItems);
			console.log('totalExtensionStorage: ', this.$store.getters.getTotalExtensionStorage);

			this.totalOrderStorage =
				usingMembership.baseChannelInfoModel.totalQuota -
				sumChannelItems +
				this.$store.getters.getTotalExtensionStorage;
		},
		shorteningWords(str) {
			// 상품명 말줄임(7자)
			if (str.length > 7) {
				return str.substr(0, 7) + '...';
			}
			return str;
		},
		async checkVirtualDomain(domain) {
			// 가상도메인 유효성 체크
			// 1. 길이조건, 패턴 체크
			if (domain.length < 3 || domain.length > 64) {
				return '길이 조건(3자리 이상 64자리 이하) 미충족';
			}

			var domainTest = domain;
			var pattern = /[\Wㄱ-ㅎㅏ-ㅣ가-힣]/;

			if (pattern.test(domainTest.replace(/[\\_.-]/g, ''))) {
				return '유효하지 않는 형식';
			}

			// 2. 중복, 사용불가 형식 등 조회 (백엔드API)
			const response = await this.$store.dispatch('checkAdmin', domain);
			return response.data ? response.data : response.errorDescription;
		},
		toggleFold(type, opertationType, typeDtl, code) {
			// 영역 접기
			this.$store.commit('TOGGLE_ADD_EXTENSION_FOLD', {
				type: type, // base, addon
				opertationType: opertationType, // ADD, EXTENSION
				typeDtl: typeDtl, // defaultFee, membershipFee, addUserFee
				code: code, // (부가일때)아이디
			});
		},
		openModal(item) {
			// 사용자 구간 모달창 OPEN
			this.$emit('openModal', item);
		},
		validateStorage() {
			const companyUsageStorage = this.$store.getters.getCompanyUsageStorage;
			this.totalCompanyUsage = companyUsageStorage.usedAccountQuota + companyUsageStorage.usedCompanyQuota;

			this.setCurrentUsage();
			this.$store.commit('CALCULATE_TOTAL_EXTENSION_STORAGE');
			this.getTotalStorage();
			console.log(
				`totalOrderStorage : ${this.totalOrderStorage}, totalCompanyUsage : ${this.totalCompanyUsage}, totalAddUserVolume : ${this.totalAddUserVolume}`,
			);

			// 총 주문 용량
			this.$store.commit('CALCULATE_TOTAL_ORDER_QUOTA', {
				totalOrderStorage: this.totalOrderStorage,
				totalAddUserVolume: this.totalAddUserVolume,
			});
			console.log(this.$store.getters.getTotalOrderQuota);

			return (
				parseInt(this.totalOrderStorage) - parseInt(this.totalCompanyUsage) + parseInt(this.totalAddUserVolume) < 0
			);
		},
		async purchaseAddExtension() {
			if (!this.isServiceTypeSAAS()) {
				this.alert({
					title: '온라인 신청 불가',
					contents: '온라인 서비스 신청이 불가합니다.<br /> 영업 담당자나 1577-3019 으로 문의해주시기 바랍니다',
				});
				return;
			}

			const usePeriod = this.$store.getters.getUsePeriod;
			if (this.validateStorage() && usePeriod !== 0) {
				this.alert({
					title: '주문 불가',
					contents: `실제 사용중인 용량보다 주문하려는 용량이 작을 수 없습니다. \n [${Math.abs(
						parseInt(this.totalOrderStorage) - parseInt(this.totalCompanyUsage) + parseInt(this.totalAddUserVolume),
					)}]GB 용량 추가 구매가 필요합니다.`,
				});
				return;
			}
			if (this.$store.getters.getTotalUserCountFocus) {
				return;
			}
			if (!this.$store.getters.getUsingMembership.enableAccessStore) {
				this.alert({
					title: '추가/연장불가',
					contents: '고객님은 추가/연장이 불가한 멤버십을 사용중 이십니다.',
				});
				return;
			}
			if (!this.$store.getters.getEnableNextOrder) {
				this.alert({
					title: '서비스 추가/연장 불가',
					contents:
						'현재 멤버십 전환 예정으로 서비스 추가/연장이 불가합니다. <br /> 신청하신 멤버십 전환 내역은 주문/구매내역에서 확인하실 수 있습니다. <br />관련 문의사항은 영업담당자나 1577-3019로 문의주시기 바랍니다.',
				});
				return;
			}
			if (!this.$store.getters.getAddExtensionUserCnt && this.requiredUserCountInspectionBaseChannel()) {
				this.alert({
					title: '사용자 수 미입력',
					contents: '사용자 수를 입력해주세요.',
				});
				return;
			}
			if (this.$store.getters.getBaseChannel.length == 0 && this.$store.getters.getAddonChannel.length == 0) {
				this.alert({
					title: '상품 선택 필요',
					contents: '상품을 선택해주세요.',
				});
				return;
			}

			if (this.$store.getters.getAddExtensionPrices.totPayPrice < 0) {
				this.alert({
					title: '멤버십 변경 불가',
					contents:
						'멤버십 변경이 불가합니다. 멤버십 변경을 원하시는 경우 영업담당자나 1577-3019로 문의주시기 바랍니다.',
				});
				return;
			}

			// 가상도메인 체크
			if (this.$store.getters.getBaseChannel && this.$store.getters.getBaseChannel.length > 0) {
				const baseChannel = this.$store.getters.getBaseChannel[0];
				if (baseChannel && baseChannel.option.length > 0) {
					const virtualDomain = baseChannel.option.filter((option) => option.itemType == 'VIRTUAL_DOMAIN');
					if (virtualDomain.length > 0) {
						if (virtualDomain[0].add.length > 0) {
							const addVD = virtualDomain[0].add[0];
							if (addVD.domainText.length < addVD.orderCount) {
								this.alert({
									title: '가상도메인 미입력',
									contents: '사용하고자 하는 가상도메인의 주소 ' + addVD.orderCount + '개 를 작성해주세요.',
								});
								return;
							} else {
								let dupleFlag = false;
								await addVD.domainText.forEach((text) => {
									if (addVD.domainText.filter((obj) => obj == text).length > 1) {
										dupleFlag = true;
									}
								});
								if (dupleFlag) {
									this.alert({
										title: '가상도메인 주소 중복',
										contents: '중복된 가상도메인 주소가 있습니다. 확인해주세요.',
									});
									return;
								}

								for (let i = 0; i < addVD.domainText.length; i++) {
									if (!addVD.domainText[i] || !addVD.domainText[i].text) {
										this.alert({
											title: '가상도메인 ' + (i + 1) + ' 미입력',
											contents: '가상도메인 ' + (i + 1) + '가 입력되지 않았습니다. 확인 해주세요.',
										});
										return;
									} else if (!addVD.domainText[i].isExisting) {
										const res = await this.checkVirtualDomain(addVD.domainText[i].text);
										if (res !== 'VALID_DOMAIN') {
											this.alert({
												title: '가상도메인 ' + (i + 1) + ' 오류',
												contents: '가상도메인 ' + (i + 1) + '(은)는 ' + res + ' 확인 해주세요.',
											});
											return;
										}
									}
								}
							}
						}

						if (virtualDomain[0].extension.length > 0) {
							const extensionVD = virtualDomain[0].extension[0];
							if (extensionVD.domainText.length < extensionVD.orderCount) {
								this.alert({
									title: '가상도메인 미입력',
									contents: '사용하고자 하는 가상도메인의 주소 ' + extensionVD.orderCount + '개 를 작성해주세요.',
								});
								return;
							} else {
								let dupleFlag = false;
								await extensionVD.domainText.forEach((text) => {
									if (extensionVD.domainText.filter((obj) => obj == text).length > 1) {
										dupleFlag = true;
									}
								});
								if (dupleFlag) {
									this.alert({
										title: '가상도메인 주소 중복',
										contents: '중복된 가상도메인 주소가 있습니다. 확인해주세요.',
									});
									return;
								}
								for (let i = 0; i < extensionVD.domainText.length; i++) {
									if (!extensionVD.domainText[i] || !extensionVD.domainText[i].text) {
										this.alert({
											title: '가상도메인 ' + (i + 1) + ' 미입력',
											contents: '가상도메인 ' + (i + 1) + '가 입력되지 않았습니다. 확인 해주세요.',
										});
										return;
									} else if (!extensionVD.domainText[i].isExisting) {
										const res = await this.checkVirtualDomain(extensionVD.domainText[i].text);
										if (res !== 'VALID_DOMAIN') {
											this.alert({
												title: '가상도메인 ' + (i + 1) + ' 오류',
												contents: '가상도메인 ' + (i + 1) + '(은)는 ' + res + ' 확인 해주세요.',
											});
											return;
										}
									}
								}
							}
						}
					}
				}
			}

			this.$router.push(`/order/Purchase`);
		},
		requiredUserCountInspectionBaseChannel() {
			const baseChannelModel = this.$store.getters.getUsingMembership.baseChannelInfoModel.baseChannelModel;
			if ('tmse_std_mailsecurity' === baseChannelModel.code) {
				return false;
			}
			return true;
		},
		isServiceTypeSAAS() {
			return this.$store.getters.getUsingMembership.serviceType == 'SAAS';
		},
	},
};
</script>
