<template>
	<div>
		<div class="header">
			<h1 class="heading-3-b">{{ item.name }} - 사용자 금액 상세</h1>
			<button class="icon" @click="closeModal()">
				<i class="ic-24 ic-close"></i>
			</button>
		</div>

		<div class="contents-wrap">
			<div class="section-wrap">
				<div class="section mb-20">
					<table class="basic-vertical-table" v-if="item.addUserTotDiscount < 0">
						<tbody>
							<tr>
								<th class=""><span class="body-2-r">할인 전 금액</span></th>
								<td class="data">
									<span class="body-2-m">{{ item.addUserTotNormalPrice | currency }}원</span>
								</td>
							</tr>
							<tr class="">
								<th class="">
									<span class="body-2-r">할인 금액</span>
								</th>
								<td class="data">
									<span class="body-2-m">{{ item.addUserTotDiscount | currency }}원</span>
								</td>
							</tr>
							<tr class="total">
								<th class=""><span class="body-2-r">할인 후 금액</span></th>
								<td class="data emphasis">
									<span class="body-2-b">{{ item.addUserTotDiscountPrice | currency }}원</span>
								</td>
							</tr>
						</tbody>
					</table>
					<table class="basic-vertical-table" v-if="item.addUserTotDiscount >= 0">
						<tbody>
							<tr class="total">
								<th class="tit"><span class="body-2-r">결제 금액</span></th>
								<td class="data emphasise">
									<span class="body-2-b">{{ item.addUserTotDiscountPrice | currency }}원</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<h2 class="subheading-b">할인 구간</h2>
				<div class="section">
					<table class="basic-table">
						<thead>
							<tr>
								<th class="">
									<span class="">구간(명)</span>
								</th>
								<th class="">
									<span class="txt">월 요금(원)</span>
								</th>
								<th class="">
									<span class="txt">수량(명)</span>
								</th>
								<th class="" v-if="item.addUserTotDiscount < 0">
									<span class="txt">할인</span>
								</th>
								<th class="" v-if="item.addUserTotDiscount < 0">
									<span class="txt">할인 금액(원)</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, index) in item.addUserDiscountMatrix" :key="index">
								<td class="">
									<span class="txt">{{ row.interval }}</span>
								</td>
								<td class="">
									<span class="txt">{{ row.price | currency }}</span>
								</td>
								<td class="">
									<span class="txt">{{ row.discountCnt }}</span>
								</td>
								<td
									class=""
									v-if="index === 0 && item.addUserTotDiscount < 0"
									:rowspan="item.addUserDiscountMatrix.length"
									v-html="row.discountInfo"
								></td>
								<td class="" v-if="item.addUserTotDiscount < 0">
									<span class="txt">{{ row.discountTotPrice | currency }}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!-- 확인 -->
		<div class="btn-area group-btn">
			<button class="solid" @click="closeModal()">확인</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
		},
	},
	mounted() {},
	methods: {
		closeModal() {
			this.$emit('close');
		},
	},
};
</script>

<style></style>
