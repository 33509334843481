<template>
	<!--container-->
	<div class="">
		<!--		<div class="section membership open" v-if="fold">-->
		<div class="section membership" :class="{ open: cardToggle }">
			<div class="card">
				<div class="membership-card-header">
					<div class="group row">
						<template v-if="membershipStatus === 'USING'">
							<div class="badge white round">사용중</div>
						</template>
						<template v-if="membershipStatus === 'END'">
							<div class="badge white round">종료 (연장 주문만 가능)</div>
						</template>
						<template v-if="membershipStatus === 'IDLE'">
							<div class="badge white round important">초과 사용 중</div>
						</template>
						<!--						<template v-if="originMembership.autoExtension">-->
						<!--							<div class="tag auto-pay">-->
						<!--								<i class="ic-24 ic-card"></i>-->
						<!--								자동카드결제-->
						<!--							</div>-->
						<!--						</template>-->
					</div>
					<h3 class="title">
						{{ originMembership.baseChannelInfoModel.baseChannelModel.name }}
						<a
							href="#none"
							class="text-toggle"
							@click="
								() => {
									this.cardToggle = !this.cardToggle;
								}
							"
							>자세히 보기</a
						>
					</h3>
					<!-- [D] 타이틀 레벨링에 의해 h1 -> h3로 변경 -->
				</div>

				<div class="contents">
					<!-- wrap_data_membership -> -->
					<!-- <div class="">  default  -->
					<ul class="badge-group summary gap8">
						<li>
							<span class="badge line normal">
								<!-- com_tag-> badge -->
								<span class="detail-txt">사용자</span>
								<span class="txt"> {{ originMembership.baseChannelInfoModel.totalUserCount }}인 </span>
							</span>
							<!-- <span class="txt"> + 추가 9명 </span> -->
						</li>
						<li>
							<span class="badge line normal">
								<!-- com_tag-> badge -->
								<span class="detail-txt">계약용량</span>
								<span class="txt"> {{ originMembership.baseChannelInfoModel.totalQuota }}GB </span>
							</span>
							<!-- <span class="txt"> + 추가 9GB </span> -->
						</li>
						<li v-if="originMembership.membershipChargeType !== 'FREE'">
							<span class="badge line normal duration">
								<template v-if="originChargeType === 'FREE'">
									<span class="txt"> 만료일 없음 </span>
								</template>
								<template v-else>
									<template v-if="membershipStatus === 'USING'">
										<span class="detail-txt">
											사용기간
											{{ originMembership.membershipBaseInfoModel.startDate }}
											~
											{{ originMembership.membershipBaseInfoModel.expirationDate }}
										</span>
										<span class="txt"> 잔여 {{ getRemainDay() }} </span>
									</template>
									<template v-if="membershipStatus === 'END'">
										<span class="txt"> 종료 (연장 주문만 가능) </span>
									</template>
									<template v-if="membershipStatus === 'IDLE'">
										<span class="txt"> 초과 사용 중 (연장 주문만 가능) </span>
									</template>
								</template>
								<!-- com_tag-> badge -->
							</span>
							<!-- <span class="txt"> + 추가 9GB </span> -->
						</li>
					</ul>
					<!-- </div> -->
					<!-- <div class="divide"></div> -->
					<!-- 부가서비스 : section open 상태에서는 display none -->
					<!--					<div class="additional">-->
					<!--						<strong>부가 서비스 요약</strong>-->
					<!--						<ul>-->
					<!--							<li>-->
					<!--								<span class="">- 경리회계 경영지원</span>-->
					<!--								&lt;!&ndash; data ->  &ndash;&gt;-->
					<!--							</li>-->
					<!--							<li>-->
					<!--								<span class="data">- 스팸 차단 서비스</span>-->
					<!--							</li>-->
					<!--						</ul>-->
					<!--					</div>-->
					<!-- 옵션 : section open 상태에서만 display -->
					<div class="option" v-if="originMembership.baseChannelInfoModel.usingChannelItemModels.length > 0">
						<strong>옵션 상품</strong>
						<table>
							<template v-for="(item, index) in originMembership.baseChannelInfoModel.usingChannelItemModels">
								<template v-if="item.channelItemType == 'VIRTUAL_DOMAIN'">
									<tr :key="'virtualDoamin_' + index" v-for="(row, index) in getVirtualDomains()">
										<th>가상도메인{{ index + 1 }}</th>
										<td>{{ row }}</td>
									</tr>
								</template>
								<tr :key="'etc_' + index" v-if="item.channelItemType !== 'VIRTUAL_DOMAIN'">
									<th>
										{{ item.channelItemModel.displaySectionModel.displayName }}
									</th>
									<td>{{ item.usingCount }}개</td>
								</tr>
							</template>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="com_promotion_store promotion_2022_02_11" v-if="usePromotionAdvertisementStore()">
			<div class="tit_wrap">
				<span class="tit">{{ getPromotionAdvertisementStoreTitle() }}</span>
			</div>
			<p class="desc" v-html="getPromotionAdvertisementStoreHtml()"></p>
			<a v-on:click="promotionAdvertisementStoreLink()">
				<span class="btn_cta" v-html="getPromotionAdvertisementStoreButton()"></span>
			</a>
		</div> -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			cardToggle: false,
			promotionAdvertisementStore: {
				use: false,
			},
		};
	},
	props: ['fold', 'originMembership', 'remainDay', 'membershipStatus', 'originChargeType'],
	watch: {
		fold() {
			this.$emit('changeFold', this.fold);
		},
	},
	created() {
		this.promotionAdvertisementStore = this.$store.getters.getPromotionAdvertisementStore;
	},
	methods: {
		getVirtualDomains() {
			if (this.originMembership.representativeGroupware) {
				return this.originMembership.representativeGroupware.virtualDomain;
			} else {
				return [];
			}
		},
		getRemainDay() {
			const remainDay = this.remainDay;
			const strRemainDay =
				(remainDay.remainMonth > 0 ? remainDay.remainMonth + '개월' : '') +
				(remainDay.remainMonth > 0 && remainDay.remainDay > 0 ? ' ' : '') +
				(remainDay.remainDay > 0 ? remainDay.remainDay + '일' : '');
			return strRemainDay;
		},
		toggleOptionFold(options, item) {
			item.foldFlag = !item.foldFlag;
			for (let idx = 0; idx < options.length; idx++) {
				if (idx >= 5) {
					options[idx].visible = !options[idx].visible;
				}
			}
		},
		usePromotionAdvertisementStore() {
			return (
				this.promotionAdvertisementStore.use &&
				this.promotionAdvertisementStore.chargeType.includes(this.originChargeType)
			);
		},
		getPromotionAdvertisementStoreTitle() {
			return this.promotionAdvertisementStore.title;
		},
		getPromotionAdvertisementStoreHtml() {
			return this.promotionAdvertisementStore.html;
		},
		getPromotionAdvertisementStoreButton() {
			return this.promotionAdvertisementStore.button;
		},
		promotionAdvertisementStoreLink() {
			window.open(this.promotionAdvertisementStore.link, '_blank');
		},
	},
	filters: {
		parseDate(value) {
			const date = new Date(value);
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			month = month >= 10 ? month : '0' + month;
			let day = date.getDate();
			day = day >= 10 ? day : '0' + day;
			return year + '-' + month + '-' + day;
		},
	},
};
</script>

<style></style>
