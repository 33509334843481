<template>
	<Fragment>
		<ul class="tab-items">
			<li
				class="tab"
				:class="{ on: idx === selectedItemIdx }"
				v-for="(membership, idx) in memberships"
				:key="idx"
				tabindex="1"
				@click="selectedMembership(idx)"
			>
				<!--				<a href="#none" class="">{{ membership.membershipName }}</a>-->
				<a href="#" class=""
					>멤버십 #{{ idx + 1 }} {{ membership.baseChannelInfoModel?.baseChannelBaseInfoModel?.siteName || '' }}</a
				>
			</li>
		</ul>
	</Fragment>
</template>

<script>
import _ from 'lodash';

export default {
	props: {
		getSelected: Function,
	},
	data() {
		return {
			selectedItemIdx: '',
			memberships: [],
		};
	},
	watch: {
		memberships() {
			return this.memberships;
		},
	},
	async created() {
		this.memberships = await this.$store.dispatch('fetchMemberships');
		this.selectedItemIdx = this.memberships ? 0 : '';
		if (!_.isEmpty(this.$route.query) && this.memberships) {
			const idx = this.memberships.findIndex((e) => e.id === parseInt(this.$route.query.membershipId));
			this.selectedItemIdx = idx === -1 ? 0 : idx;
		}
		this.selectedMembership(this.selectedItemIdx);
	},
	methods: {
		selectedMembership(idx) {
			this.selectedItemIdx = idx;
			this.$emit('getSelected', this.memberships[this.selectedItemIdx]?.id || null);
		},
	},
};
</script>

<style scoped></style>
