import { debounce } from 'lodash';

let itemCode;
export const optionOperation = {
	methods: {
		// TODO TotalUserCount.vue에도 debounce가 있는데, 공통화 할 필요가 있음.
		debounceHandler: debounce(async function (item, serviceType, service, addOrSubtract) {
			await addOrSubtract(item, serviceType, service);
		}, 300),
		async updateOptionCount(item, serviceType, service, addOrSubtract) {
			// 동일한 품목이 아니면 바로 품목 금액 계산 로직 타도록 함.
			if (itemCode !== item.code) {
				itemCode = item.code;
				await addOrSubtract(item, serviceType, service);
				// 이전 품목과 동일한 품목이면 기다렸다가 품목 계산 금액 로직 타도록 함.
			} else {
				await this.debounceHandler(item, serviceType, service, addOrSubtract);
			}
		},
		// 아이템 그룹별 분리
		async seperateByItemGroup(items) {
			// 용량 추가 정렬
			if (items.length > 0) {
				items.forEach((item) => {
					if (!item.groupModel) {
						item['groupModel'] = {
							code: item.code,
							name: item.name,
							isGroupped: false,
						};
					} else {
						item.groupModel['isGroupped'] = true;
					}
				});

				items.sort((a, b) => {
					return a.groupModel.code < b.groupModel.code ? -1 : a.groupModel.code > b.groupModel.code ? 1 : 0;
				});
			}

			let optionItems = [];
			let prevGroupModel = null;
			let sameGroupModelItems = [];
			items.forEach((optionItem) => {
				if (JSON.stringify(prevGroupModel) !== JSON.stringify(optionItem.groupModel)) {
					if (sameGroupModelItems.length > 0) {
						let data = {
							groupModel: prevGroupModel,
							items: sameGroupModelItems,
						};
						optionItems.push(data);
						sameGroupModelItems = [];
					}
					prevGroupModel = optionItem.groupModel;
				}
				optionItem['domainText'] = [];
				sameGroupModelItems.push(optionItem);
			});
			if (sameGroupModelItems.length > 0) {
				let data = {
					groupModel: prevGroupModel,
					items: sameGroupModelItems,
				};
				optionItems.push(data);
			}

			return optionItems;
		},
		// 옵션 상품 수량 set (처음 open 시, minimum 값으로)
		async setCntOptionItem(item, dependonService, count) {
			// 단위 수량
			item.orderCount = count;
			let serviceType = '';
			if (dependonService.code.indexOf('ADDON-') > -1) {
				serviceType = 'ADDON';
			} else {
				serviceType = 'BASE';
			}
			// type에 따른 데이터셋을 찾고, 그 안의 옵션상품 카운트를 증가
			item.gapCount = item.orderCount - item.usingCount;
			let freeTrialPolicyModel = null;
			let isSelected = true;
			if (serviceType == 'ADDON') {
				freeTrialPolicyModel = dependonService.freeTrialPolicyModel;
				isSelected = await this.isSelectedAddon(dependonService.code);
			}
			const service = {
				name: dependonService.name,
				code: dependonService.code,
				serviceCategoryModel: dependonService.serviceCategoryModel,
				defaultFeePolicyModel: dependonService.defaultFeePolicyModel,
				addUserIntervalPolicyModel: dependonService.addUserIntervalPolicyModel,
				membershipFeePolicyModel: dependonService.membershipFeePolicyModel,
				freeTrialPolicyModel: freeTrialPolicyModel,
				chargeType: dependonService.chargeType,
				termsSectionModel: dependonService.termsSectionModel,
				usingCount: 1, // 해당 부가서비스가 사용중인지
				isSelected: isSelected,
			};
			await this.addUpdateOptionByCase(item, serviceType, service);
		},
		// 기본서비스 옵션 상품 수량 추가
		async upCntOptionItem(item, dependonService) {
			// 개수
			let cntUnit = this.unitCount(item);

			if (item.addBlock) {
				if (item.orderCount + cntUnit > item.usingCount) {
					this.alert({ title: '상품 추가 불가', contents: item.name + '은 추가 구매가 불가한 상품입니다.' });
					return;
				}
			}

			if (item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity) {
				if (
					item.channelItemSalesSectionModel.channelItemSalesPolicyModel.maximum > 0 &&
					item.orderCount + cntUnit > item.channelItemSalesSectionModel.channelItemSalesPolicyModel.maximum
				) {
					this.alert({
						title: '최대 구매 수량 제한',
						contents:
							item.name +
							'은 최대' +
							item.channelItemSalesSectionModel.channelItemSalesPolicyModel.maximum +
							'개 까지 구매 가능합니다.',
					});
					return;
				}
			}
			// 라디오 버튼 처리
			const serviceIdx = this.availableOptionItemsPerService.findIndex(
				(obj) => obj.service.code == dependonService.code,
			);
			if (item.groupModel.isGroupped) {
				if (item.groupModel.name.indexOf('용량') == -1 && item.groupModel.name.indexOf('가상') == -1) {
					if (item.groupModel.selectionType == 'RADIO') {
						const optionIdx = this.availableOptionItemsPerService[serviceIdx].options.findIndex(
							(option) => option.groupModel.code == item.groupModel.code,
						);
						await this.availableOptionItemsPerService[serviceIdx].options[optionIdx].items.reduce(
							(previous, current) => {
								return previous.then(async () => {
									if (current.code !== item.code && current.orderCount > 0) {
										await this.downCntOptionItem(current, dependonService);
									}
								});
							},
							Promise.resolve(),
						);
					}
				}
			}

			let serviceType = '';
			if (dependonService.code.indexOf('ADDON-') > -1) {
				serviceType = 'ADDON';
			} else {
				serviceType = 'BASE';
			}
			// type에 따른 데이터셋을 찾고, 그 안의 옵션상품 카운트를 증가
			item.orderCount = item.orderCount + cntUnit;
			item.gapCount = item.orderCount - item.usingCount;
			let freeTrialPolicyModel = null;
			let isSelected = true;
			if (serviceType == 'ADDON') {
				freeTrialPolicyModel = dependonService.freeTrialPolicyModel;
				isSelected = await this.isSelectedAddon(dependonService.code);
			}
			const service = {
				name: dependonService.name,
				code: dependonService.code,
				serviceCategoryModel: dependonService.serviceCategoryModel,
				defaultFeePolicyModel: dependonService.defaultFeePolicyModel,
				addUserIntervalPolicyModel: dependonService.addUserIntervalPolicyModel,
				membershipFeePolicyModel: dependonService.membershipFeePolicyModel,
				freeTrialPolicyModel: freeTrialPolicyModel,
				chargeType: dependonService.chargeType,
				termsSectionModel: dependonService.termsSectionModel,
				usingCount: 1, // 해당 부가서비스가 사용중인지
				isSelected: isSelected,
			};
			await this.updateOptionCount(item, serviceType, service, this.addUpdateOptionByCase);
		},
		// 기본서비스 옵션 상품 수량 취소
		// type : VIRTUAL_DOMAIN/ VOLUME/ ETC
		async checkDownCntOptionItem(item, dependonService, type, index, domainExisting) {
			if (item.orderCount < 1) {
				return;
			}
			if (this.extensionPeriod == 0 && domainExisting) {
				this.alert({
					title: '사용중 가상 도메인 취소 불가',
					contents: '연장 주문이 아닌 경우, 사용중인 가상 도메인은 취소 불가합니다.',
				});
				return;
			}

			let cntUnit = this.unitCount(item);

			if (item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity) {
				if (
					item.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum > 0 &&
					item.orderCount - cntUnit < item.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum
				) {
					this.alert({
						title: '최소 구매 수량 필수',
						contents:
							item.name +
							'은 최소' +
							item.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum +
							'개 구매 하셔야 합니다.',
					});
					return;
				}
			}

			if (this.extensionPeriod === 0 && item.usingCount > item.orderCount - cntUnit && !this.isChangeOrder) {
				this.checkReduceData('OPTION', item.orderCount - cntUnit, item.usingCount, this.extensionPeriod);
				return;
			} else if (item.usingCount > item.orderCount - cntUnit) {
				if (item.itemType == 'MEETING_ROOM') {
					// 고톡
					this.alert({
						title: '구매 수량 취소',
						contents: `${item.name} 구매 취소 시 최근생성된 순서로 대화방 5개가 삭제됩니다.<br/> 구매 수량을 삭제하시겠습니까?`,
						buttons: [
							{
								text: '확인',
								callback: () => {
									this.downCntOptionItem(item, dependonService, type, index);
								},
							},
							{
								text: '취소',
								callback: () => {
									return;
								},
							},
						],
					});
				} else {
					this.alert({
						title: '구매 수량 취소',
						contents: '구매 취소 시 ' + item.name + '을 사용할 수 없습니다. <br/> 구매 수량을 삭제하시겠습니까?',
						buttons: [
							{
								text: '삭제',
								callback: () => {
									this.downCntOptionItem(item, dependonService, type, index);
								},
							},
							{
								text: '취소',
								callback: () => {},
							},
						],
					});
				}
			} else {
				await this.downCntOptionItem(item, dependonService, type, index);
			}
		},
		async downCntOptionItem(item, dependonService, type, index) {
			if (item.orderCount < 1) {
				return;
			}
			let cntUnit = this.unitCount(item);
			if (item.orderCount - cntUnit < item.usingCount && this.extensionPeriod === 0 && !this.isChangeOrder) {
				return;
			}
			let serviceType = '';
			if (dependonService.code.indexOf('ADDON-') > -1) {
				serviceType = 'ADDON';
			} else {
				serviceType = 'BASE';
			}
			// type에 따른 데이터셋을 찾고, 그 안의 옵션상품 카운트를 증가
			item.orderCount = item.orderCount - cntUnit;
			item.gapCount = item.orderCount - item.usingCount;
			let freeTrialPolicyModel = null;
			let isSelected = true;
			if (serviceType == 'ADDON') {
				freeTrialPolicyModel = dependonService.freeTrialPolicyModel;
				isSelected = await this.isSelectedAddon(dependonService.code);
			}
			const service = {
				name: dependonService.name,
				code: dependonService.code,
				serviceCategoryModel: dependonService.serviceCategoryModel,
				defaultFeePolicyModel: dependonService.defaultFeePolicyModel,
				addUserIntervalPolicyModel: dependonService.addUserIntervalPolicyModel,
				membershipFeePolicyModel: dependonService.membershipFeePolicyModel,
				freeTrialPolicyModel: freeTrialPolicyModel,
				chargeType: dependonService.chargeType,
				termsSectionModel: dependonService.termsSectionModel,
				usingCount: 1, // 해당 부가서비스가 사용중인지
				isSelected: isSelected,
			};
			await this.updateOptionCount(item, serviceType, service, this.subtractOptionByCase);

			if (type == 'VIRTUAL_DOMAIN') {
				item.domainText.splice(index - 1, 1);
			}
		},
		async addUpdateOptionByCase(item, serviceType, service) {
			let isSelectedService = true;
			if (service.code.indexOf('ADDON-') > -1) {
				const addonIdx = this.addonChannelList.findIndex((addon) => addon.code == service.code);
				if (this.addonChannelList[addonIdx]) {
					isSelectedService = this.addonChannelList[addonIdx].isSelected;
				}
			}
			// 1) 연장 = 총인원 * 개월
			if (
				this.extensionPeriod > 0 &&
				item.orderCount > 0 &&
				(item.channelItemSalesSectionModel.billingType !== 'ONCE' ||
					this.$store.getters.getFixedOrderType === 'EXTENSION') &&
				isSelectedService
			) {
				let extensionOption = { ...item };
				extensionOption = await this.setOptionCalcInfo(
					extensionOption,
					service,
					this.totUserCnt,
					this.extensionPeriod,
					extensionOption.orderCount,
					'EXTENSION',
				);

				await this.commitWithPayload(
					'OPTION',
					'PLUS_SERVICE_OPTION',
					serviceType,
					service,
					'EXTENSION',
					extensionOption,
				);
			} else if (this.extensionPeriod === 0 && (item.orderCount > 0 || !isSelectedService)) {
				// 연장 -> 연장취소 인 경우, 연장 데이터 지우기
				let removeOption = { ...item };
				await this.commitWithPayload(
					'OPTION',
					'REMOVE_SERVICE_OPTION',
					serviceType,
					service,
					'EXTENSION',
					removeOption,
				);
			}

			// 2) 추가
			if (
				item.usingCount > 0 ||
				(item.usingCount === 0 && item.orderCount > 0 && !(await this.isConvertedAccount(service))) // 변경된 회계서비스 여부
			) {
				// 사용중 or 신규추가
				let addOption = { ...item };
				if (this.isChangeOrder) {
					// 변경 주문일 경우
					addOption = await this.setOptionCalcInfo(addOption, service, 1, this.remainDay, addOption.orderCount, 'ADD'); // 개수 추가
				} else if (addOption.usingCount > 0) {
					// 사용중 (사용자 추가 or 개수 추가)
					if (addOption.channelItemSalesSectionModel.salesType == 'USER_BASED_FEE' && this.addUserCnt > 0) {
						// 사용자 추가
						addOption = await this.setOptionCalcInfo(addOption, service, this.addUserCnt, this.remainDay, 1, 'ADD');
					} else if (addOption.gapCount > 0) {
						// 개수 추가일 경우
						addOption = await this.setOptionCalcInfo(addOption, service, 1, this.remainDay, addOption.gapCount, 'ADD'); // 개수 추가
					} else {
						return;
					}
				} else if (addOption.usingCount === 0 && addOption.orderCount > 0) {
					// 신규추가
					if (addOption.channelItemSalesSectionModel.salesType == 'USER_BASED_FEE' && this.addUserCnt > 0) {
						// 사용자 과금
						addOption = await this.setOptionCalcInfo(
							addOption,
							service,
							this.totUserCnt, // 전체 인원
							this.remainDay,
							1,
							'ADD',
						);
					} else if (addOption.gapCount > 0) {
						// 개수 과금
						addOption = await this.setOptionCalcInfo(
							addOption,
							service,
							1,
							this.remainDay,
							addOption.orderCount, // 전체 개수
							'ADD',
						);
					} else {
						return;
					}
				} else {
					return;
				}
				await this.commitWithPayload(
					'OPTION', // type
					'PLUS_SERVICE_OPTION', // methodName
					serviceType, // serviceType
					service, // service
					'ADD', // operationType
					addOption, // option
				);
			}
		},
		async subtractOptionByCase(item, serviceType, service) {
			if (
				this.extensionPeriod > 0 &&
				item.orderCount > 0 &&
				(item.channelItemSalesSectionModel.billingType !== 'ONCE' ||
					this.$store.getters.getFixedOrderType === 'EXTENSION') &&
				service.isSelected
			) {
				// 연장 = 총인원 * 개월
				let extensionOption = { ...item };
				extensionOption = await this.setOptionCalcInfo(
					extensionOption,
					service,
					this.totUserCnt,
					this.extensionPeriod,
					extensionOption.orderCount,
					'EXTENSION',
				);

				await this.commitWithPayload(
					'OPTION',
					'MINUS_SERVICE_OPTION',
					serviceType,
					service,
					'EXTENSION',
					extensionOption,
				);
			} else if ((this.extensionPeriod > 0 && item.orderCount == 0 && service.isSelected) || !service.isSelected) {
				let removeOption = { ...item };
				await this.commitWithPayload(
					'OPTION',
					'REMOVE_SERVICE_OPTION',
					serviceType,
					service,
					'EXTENSION',
					removeOption,
				);
			}

			if (
				this.$store.getters.getFixedOrderType !== 'EXTENSION' &&
				(item.usingCount > 0 || (item.usingCount === 0 && item.orderCount > 0))
			) {
				let addOption = { ...item };
				if (this.isChangeOrder) {
					// 변경 주문일 경우
					addOption = await this.setOptionCalcInfo(addOption, service, 1, this.remainDay, addOption.orderCount, 'ADD'); // 개수 추가
				} else if (addOption.usingCount > 0) {
					// 사용중
					if (addOption.channelItemSalesSectionModel.salesType == 'USER_BASED_FEE' && this.addUserCnt > 0) {
						// 사용자 추가 일 경우 (사용자 과금은 개수 체크 X)
						addOption = await this.setOptionCalcInfo(addOption, service, this.addUserCnt, this.remainDay, 1, 'ADD');
					} else if (addOption.gapCount >= 0) {
						// 개수 추가일 경우
						addOption = await this.setOptionCalcInfo(addOption, service, 1, this.remainDay, addOption.gapCount, 'ADD'); // 개수 추가
					} else {
						return;
					}
				} else if (addOption.usingCount == 0 && addOption.orderCount > 0) {
					// 신규추가

					if (addOption.channelItemSalesSectionModel.salesType == 'USER_BASED_FEE' && this.addUserCnt > 0) {
						// 사용자 과금
						addOption = await this.setOptionCalcInfo(
							addOption,
							service,
							this.totUserCnt, // 전체 인원
							this.remainDay,
							1,
							'ADD',
						);
					} else if (addOption.gapCount > 0) {
						// 개수 과금
						addOption = await this.setOptionCalcInfo(
							addOption,
							service,
							1,
							this.remainDay,
							addOption.orderCount, // 전체 개수
							'ADD',
						);
					} else {
						return;
					}
				} else {
					return;
				}
				await this.commitWithPayload('OPTION', 'MINUS_SERVICE_OPTION', serviceType, service, 'ADD', addOption);
			} else if (item.gapCount === 0) {
				let removeOption = { ...item };
				await this.commitWithPayload('OPTION', 'REMOVE_SERVICE_OPTION', serviceType, service, 'ADD', removeOption);
			}
		},
		// 옵션 상품 금액 계산 (type : ADD/EXTENSION)
		async setOptionCalcInfo(item, service, userCnt, usePeriod, orderCount, type) {
			// TODO 공통화
			let orderInfoModel = {
				membershipModel: {
					id: this.$store.getters.getUsingMembership.id,
				},
				partnerSimpleModel: {
					partnerId: this.$store.getters.getUsingMembership.partnerSimpleModel.partnerId,
				},
				baseInfoModel: {
					orderType: '',
				},
				serviceType: item.serviceCategoryModel.serviceType,
				itemCount: orderCount,
				baseChannelOrderInfoModel: {
					applyDate: '',
					endDate: '',
					baseChannelModel: {
						code: service.code, // 서비스 아이템 코드
					},
					itemOrderDetails: [
						{
							channelItemSimpleModel: {
								id: item.id,
							},
							existingCount: 0,
							addCount: 0,
							billingType: item.salesRecognitionType,
							channelItemType: item.itemType,
							itemCode: item.code,
							price: item.channelItemSalesSectionModel.price,
						},
					],
				},
			};

			const date = this.$store.getters.getDates;
			if (type === 'USERADD' || type === 'ADD') {
				orderInfoModel.baseInfoModel.orderType = 'ADD';
				// 신요금제 전환 대상이면 [추가연장 == 추가하면서 연장] 하므로 applyDate 는 연장기간과 동일
				const applyDate = this.$store.getters.isExtensionOrAddExtensionOrderWithConversionProduct
					? date.extensionStartDate
					: date.addStartDate;
				orderInfoModel.baseChannelOrderInfoModel.applyDate = applyDate;
				orderInfoModel.baseChannelOrderInfoModel.endDate = date.addEndDate;
			} else if (type === 'EXTENSION') {
				orderInfoModel.baseInfoModel.orderType = 'EXTENSION';
				orderInfoModel.baseChannelOrderInfoModel.applyDate = date.extensionStartDate;
				orderInfoModel.baseChannelOrderInfoModel.endDate = date.extensionEndDate;
			}

			if (orderInfoModel.baseInfoModel.orderType === 'EXTENSION') {
				orderInfoModel.baseChannelOrderInfoModel.itemOrderDetails[0].existingCount = orderCount;
			} else if (orderInfoModel.baseInfoModel.orderType === 'ADD') {
				orderInfoModel.baseChannelOrderInfoModel.itemOrderDetails[0].addCount = orderCount;
			}

			const itemCalculateRequestModel = {
				orderInfoModel: orderInfoModel,
				channelItemType: 'OPTION',
			};
			const response = await this.$store.dispatch('CALCULATE_ITEM_PRICE', itemCalculateRequestModel);

			item['userCnt'] = userCnt;
			item['usePeriod'] = usePeriod;
			item.orderCount = orderCount;
			item['finalPrice'] = response.data.priceModel.totalFinalPrice;
			return item;
		},
		unitCount(item) {
			let unitCnt = 1;
			const dependsOnUserCount = item.channelItemSalesSectionModel.channelItemSalesPolicyModel.dependsOnUserCount; // 기본서비스 수대로 과금
			const useOrderQuantity = item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity; // 주문수량 설정
			const isCharge = !item.channelItemSalesSectionModel.noCharge; // 유료상품여부

			if (dependsOnUserCount && isCharge) {
				unitCnt = this.totUserCnt;
			} else if (useOrderQuantity) {
				unitCnt = item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit;
			}

			return unitCnt;
		},
	},
};
