<template>
	<div class="group row">
		<label class="definition label">
			<span class="">사용자 수</span>
			<i class="ic-24 ic-info">
				<span class="tooltip-layer left">
					총 100명 이상 사용 시,<br />
					사용자의 구간에 따라 최소 20% ~ 최대 60% 까지 할인율이 적용됩니다 (장기 선납 할인과 중복 적용)
				</span>
			</i>
		</label>
		<div class="form num">
			<span class="group-inp-num">
				<button
					class="line mini"
					@click="totalUserCount = checkChangeCount(extensionPeriod, existingUserCnt, totalUserCount - 1)"
				>
					<i class="ic-16 ic-reduce"></i>
				</button>
				<input
					v-doms-inputValidate:onlyNumber=""
					class=""
					type="number"
					v-model="totalUserCount"
					name="quantity"
					@focus="focusIn()"
					@blur="focusOut()"
				/>
				<button
					class="line mini"
					@click="
						[
							parseInt(totalUserCount) <= 9999
								? (totalUserCount = parseInt(totalUserCount) + 1)
								: (totalUserCount = parseInt(totalUserCount)),
						]
					"
				>
					<i class="ic-16 ic-grow"></i>
				</button>
			</span>
			<div class="details">
				<span class="txt">기존 {{ existingUserCnt }}명</span>
				<span v-if="addUserCnt > 0" class="txt primary">{{ addUserCnt }}명 추가</span>
				<span v-if="addUserCnt < 0" class="txt important">{{ addUserCnt }}명 감소</span>
			</div>
		</div>
	</div>
</template>

<script>
import { debounce } from 'lodash';
import mixinsAlert from '@/components/common/mixinsAlert';
export default {
	mixins: [mixinsAlert],
	props: [
		'value',
		'existingUserCnt',
		'addUserCnt',
		'usingMembership',
		'extensionPeriod',
		'currentChargeType',
		'checkUserCount',
	],
	data() {
		return {
			totalUserCount: this.value,
		};
	},
	watch: {
		value() {
			this.totalUserCount = this.value;
		},
		totalUserCount() {
			this.updateTotalUserCount();
		},
	},
	methods: {
		updateTotalUserCount: debounce(async function () {
			await this.$emit('input', this.totalUserCount);
		}, 300),
		checkChangeCount(extensionPeriod, originCount, newCount) {
			if (this.isUnderDefaultUserCount(newCount)) {
				this.openAlert('구매 취소가 불가능합니다.', '현재 기본사용자 이하로는 구매 취소가 불가합니다.');
				this.totalUserCount = originCount;
				return originCount;
			}

			if (newCount < 0) {
				this.totalUserCount = originCount;
				return originCount;
			}
			if (
				!(
					this.$store.getters.getOriginMembershipChargeType !== this.$store.getters.getNewMembershipChargeType &&
					this.$store.getters.getNewMembershipChargeType === 'PAY'
				) &&
				extensionPeriod == 0 &&
				originCount > newCount
			) {
				this.checkReduceData('USERCOUNT', newCount, originCount, extensionPeriod);
				this.totalUserCount = originCount;
				return originCount;
			} else {
				this.totalUserCount = newCount;
				return newCount;
			}
		},
		isUnderDefaultUserCount(orderCount) {
			const currentDefaultUserCount =
				this.usingMembership.baseChannelInfoModel.baseChannelModel.defaultFeePolicyModel.defaultUserNum;
			if (parseInt(currentDefaultUserCount) > parseInt(orderCount)) {
				return true;
			}
			return false;
		},
		checkReduceData(type, orderCount, usingCount, extensionPeriod) {
			if (type == 'ADDON' && usingCount > 0 && extensionPeriod == 0) {
				this.openAlert('구매 취소가 불가능합니다.', '구매 취소 요청에 취소가 불가능한 부가서비스가 포함되었습니다.');
				return;
			}

			if (
				(type == 'USERCOUNT' || type == 'OPTION') &&
				usingCount > 0 &&
				extensionPeriod == 0 &&
				orderCount < usingCount
			) {
				this.openAlert('구매 취소가 불가능합니다.', '현재 사용중인 수 보다 적게는 구매 취소가 불가합니다.');
				return;
			}
		},
		openAlert(title, content) {
			this.alert({
				title: title,
				contents: content,
			});
		},
		focusIn() {
			this.$store.commit('SET_TOTAL_USER_COUNT_FOCUS', true);
		},
		focusOut() {
			this.checkUserCount();
			this.$store.commit('SET_TOTAL_USER_COUNT_FOCUS', false);
		},
	},
};
</script>

<style></style>
