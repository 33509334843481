<template>
	<div class="card list group column">
		<div class="group row">
			<div class="group headline">
				<h4 class="">세팅상품</h4>
			</div>
		</div>
		<!-- <span class="tit"
			>{{ option.groupModel.name }}<a v-if="option.groupModel.selectionType == 'RADIO'"> (택 1)</a></span
		> -->
		<div class="group column">
			<table class="">
				<tbody>
					<tr class="table-row" v-for="item in option.items" :key="item.code">
						<td class="">
							<i class="ic-16 ic-depth"></i>
							<span class="txt">{{ item.displaySectionModel.displayName }}</span>
							<span v-if="item.displaySectionModel.useTooltip" class="">
								<i class="ic-16 ic-info">
									<span class="tooltip-layer left">
										{{ item.displaySectionModel.tooltipDesc }}
									</span>
								</i>
							</span>
							<span v-if="item.displaySectionModel.useIcon" class="badge">
								<span v-if="item.displaySectionModel.iconType == 'HOT'" class="txt">HOT</span>
								<span v-if="item.displaySectionModel.iconType == 'RECOMMENDATION'" class="txt">추천</span>
							</span>
							<span class="badge line primary" v-if="item.usingCount > 0">
								<span class="txt">사용중</span>
							</span>
						</td>
						<td
							class="price-area"
							:colspan="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity ? '2' : '0'"
						>
							<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
								<span class="price">견적가</span>
							</template>
							<template v-else>
								<span v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'" class="price"
									>{{ item.channelItemSalesSectionModel.price | currency }}원 /{{
										item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit
									}}개</span
								>
								<span v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'" class="price"
									>{{ item.channelItemSalesSectionModel.price | currency }}원</span
								>
							</template>

							<span class="price billing_type">
								{{ item.channelItemSalesSectionModel.billingType | billingTypeDesc }}
							</span>
							<span
								class="group-inp-num"
								v-if="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity"
							>
								<button class="line mini" v-on:click="downCntOptionItem(item, optionPerService.service)">
									<i class="ic-16 ic-reduce"></i>
								</button>
								<input class="" type="number" name="quantity" readonly v-model="item.orderCount" />
								<button class="line mini" v-on:click="upCntOptionItem(item, optionPerService.service)">
									<i class="ic-16 ic-grow"></i>
								</button>
							</span>
						</td>
						<td class="check" v-if="!item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity">
							<div class="group-chk">
								<input
									type="checkbox"
									:disabled="isItemDisable(item)"
									:checked="isItemChecked(item)"
									v-on:click="
										item.orderCount == 0
											? upCntOptionItem(item, optionPerService.service)
											: checkDownCntOptionItem(item, optionPerService.service)
									"
								/>
								<label class="label"></label>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { option } from '@/components/apply/user/store/addExtension/optionItem/MixinsOption.js';
export default {
	mixins: [option],
	props: ['option', 'optionPerService'],
};
</script>

<style scoped>
.billing_type {
	margin-left: -10px;
}
</style>
