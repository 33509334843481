<template>
	<Fragment>
		<div
			class="additional-membership"
			v-if="usingMembership.addonChannelInfoModels && usingMembership.addonChannelInfoModels.length"
		>
			<div
				class="card"
				v-for="(item, index) in usingMembership.addonChannelInfoModels"
				:key="item.addonChannelModel.code + index"
			>
				<div class="membership-card-header">
					<h3 class="title">
						<i class="ic-24" :class="addonChannelIconClass(item.addonChannelModel.name)"></i>
						{{ item.addonChannelModel.name }}
						<p class="description">부가서비스</p>
					</h3>
				</div>

				<div class="contents">
					<!-- wrap_data_membership -> -->
					<!-- <div class="">  default  -->
					<ul class="badge-group summary gap8">
						<li>
							<span class="badge line normal">
								<!-- com_tag-> badge -->
								<span class="detail-txt">사용자</span>
								<span class="txt">{{ item.totalUserCount }}인 </span>
							</span>
							<!-- <span class="txt"> + 추가 9명 </span> -->
						</li>
						<li v-if="usingMembership.membershipChargeType !== 'FREE'">
							<span class="badge line normal duration">
								<!-- com_tag-> badge -->
								<span class="detail-txt">
									{{ today }}~{{ getFormateDate(usingMembership.membershipBaseInfoModel.expirationDate) }}
								</span>
							</span>
							<!-- <span class="txt"> + 추가 9GB </span> -->
						</li>
					</ul>
					<!-- </div> -->
					<!-- <div class="divide"></div> -->
					<!-- 옵션 : section open 상태에서만 display -->
					<div class="option">
						<strong>옵션 상품</strong>
						<table>
							<template v-if="item.usingChannelItemModels.length == 0">
								<tr>
									<td>사용중인 옵션 상품이 없습니다.</td>
								</tr>
							</template>
							<template v-else>
								<template v-for="(data, index) in sortDefault(item.usingChannelItemModels)">
									<tr
										:key="data.channelItemModel.code + 'etc' + index"
										v-if="data.channelItemType !== 'VIRTUAL_DOMAIN'"
									>
										<th class="tit">
											<span
												v-if="addonChannelIconClass(data.channelItemModel.name, true)"
												class="ic_svc"
												:class="addonChannelIconClass(data.channelItemModel.name, true)"
											></span>
											{{ data.channelItemModel.name }}
										</th>
										<td class="data">{{ data.usingCount }}개</td>
									</tr>
								</template>
							</template>
						</table>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
export default {
	data() {
		return {
			today: this.$moment().format('YYYY-MM-DD'),
		};
	},
	props: {
		usingMembership: Object,
	},
	methods: {
		addonChannelIconClass(addonChannelName, isOption) {
			if (addonChannelName.indexOf(this.$constants.ACCOUNTING) > -1) {
				return isOption ? 'ic-accounting_s' : 'ic-accounting';
			} else if (addonChannelName === this.$constants.BIZPLAY) {
				return 'ic-bizplay';
			} else if (addonChannelName === this.$constants.TMSE_S) {
				return 'ic-tmse-s';
			} else if (addonChannelName === this.$constants.TMSE_E) {
				return 'ic-tmse-e';
			} else if (addonChannelName === this.$constants.TMSE_SHARE) {
				return 'ic-tmse-t';
			}
			return undefined;
		},
		toggleOptionFold(options, item) {
			item.foldFlag = !item.foldFlag;
			for (let idx = 0; idx < options.length; idx++) {
				if (idx >= 5) {
					options[idx].visible = !options[idx].visible;
				}
			}
		},
		getFormateDate(val) {
			const date = new Date(val);
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			month = month >= 10 ? month : '0' + month;
			let day = date.getDate();
			day = day >= 10 ? day : '0' + day;
			return year + '-' + month + '-' + day;
		},
		sortDefault(usingChannelItemModels) {
			const cloneItemModels = cloneDeep(usingChannelItemModels);
			let sortItem = cloneItemModels.sort((a, b) => {
				if (a.itemName == '경영지원' && a.channelItemType == 'DEFAULT') {
					return -1;
				} else {
					return a.id - b.id;
				}
			});
			return sortItem;
		},
	},
};
</script>

<style></style>
