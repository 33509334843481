// const data = [
// 	{
// 		"type": "CUSTOMER_APPLY",
// 		"data": {
// 			"use": true,
// 			"head": "중소기업",
// 			"body": "20% 할인 + 100GB 용량 더드림 이벤트",
// 			"tail": "2023.8.1(화) ~ 2023.9.30(토)"
// 		}
// 	},
// 	{
// 		"type": "CUSTOMER_STORE",
// 		"data": {
// 			"use": true,
// 			"tilte": "다우오피스 중소기업 20%할인 + 용량 더드림 프로모션",
// 			"html": "다우오피스 유료 전환 시, <strong>20% 할인 + 100GB 용량 더드림 프로모션을 제공</strong>해 드립니다. (9월 30일 까지)<br> 자세한 사항은 1577-3019(1번)으로 문의해주세요.",
// 			"button": "프로모션 신청 문의하기",
// 			"link": "https://daouoffice.com/2023p_sme2.jsp",
// 			"chargeType": [
// 				"FREE",
// 				"FREE_MONTHLY"
// 			]
// 		}
// 	},
// 	{
// 		"type": "CUSTOMER_TOP",
// 		"data": {
// 			"banners": [
// 				{
// 					"imgUrl": "https://doad.daouoffice.com/img/sample-ad-01.png",
// 					"link": "https://care.daouoffice.co.kr/hc/ko/articles/19242093005849"
// 				},
// 				{
// 					"imgUrl": "https://doad.daouoffice.com/img/sample-ad-02.png",
// 					"link": "https://www.instagram.com/daouoffice_official/"
// 				}
// 			]
// 		}
// 	},
// 	{
// 		"type": "CUSTOMER_HOME",
// 		"data": {
// 			"imgUrl": "https://doad.daouoffice.com/img/main_banner_small2.png",
// 			"link": "https://daouoffice.com/cartaxbiz.jsp"
// 		}
// 	},
// 	{
// 		"type": "PROMOTION_DISCOUNT",
// 		"data": {
// 			"use": true,
// 			"discount": 50,
// 			"allowDuplicate": false,
// 			"orderType": [
// 				"useNew"
// 			],
// 			"serviceType": [
// 				"SAAS"
// 			],
// 			"periodDisplay": [
// 				{
// 					"display": "12개월(50%프로모션할인)",
// 					"value": 12
// 				},
// 				{
// 					"display": "1개월(자동카드결제)",
// 					"value": 1
// 				}
// 			],
// 			"usePeriod": 12,
// 			"itemType": [
// 				"ADD_USER"
// 			]
// 		}
// 	}
// ];
export const promotion = {
	methods: {
		async getTodayPromotions() {
			let { data } = await this.$store.dispatch('fetchTodayPromotionList');
			await this.$store.commit('SET_PROMOTION', data);
		},
	},
};
