<template>
	<div class="group column additional-service" v-if="visibleAddonServices.length">
		<label class="definition label">
			<span class=""> 부가 서비스 </span>
		</label>
		<div class="form group row wrap">
			<template v-for="(item, index) in visibleAddonServices">
				<div
					class="card"
					:class="{
						active: item.isSelected,
					}"
					@click="canReduceItem(item, extensionPeriod)"
					:key="index"
					v-if="item.visible"
				>
					<div class="group-chk">
						<input
							:disabled="isDisabledAddon(item)"
							type="checkbox"
							v-on:click="selectAddonService(item)"
							:checked="item.isSelected"
						/>
						<label class="label">
							<div class="service-card">
								<h4>
									<i v-if="getItemClass(item)" class="ic-24" :class="getItemClass(item)"></i>
									{{ item.name }}
								</h4>
								<!-- <span v-if="item.freeTrialPolicyModel.usePolicy == true" class="com_tag_badge">
							<span class="txt" v-if="item.usingCount == 0"
								>{{ item.freeTrialPolicyModel.usagePeriod }}개월 무료 체험</span
							>
							<span class="txt" v-if="item.usingCount > 0">유료 전환 완료</span>
						</span> -->
								<!-- <span class="com_tag tag_s" v-if="item.usingCount > 0">
							<span class="txt">사용중</span>
						</span> -->

								<table class="">
									<tbody>
										<tr>
											<th class=""><span class="">기본료</span></th>
											<td class="">
												<span class=""
													>{{ item.defaultFeePolicyModel.price | currency }}원
													{{ item.defaultFeePolicyModel.billingType | billingTypeDesc }}</span
												>
											</td>
										</tr>
										<tr>
											<th class=""><span class="">설치비</span></th>
											<td class="">
												<span class=""
													>{{ item.membershipFeePolicyModel.price | currency }}원
													{{ item.membershipFeePolicyModel.billingType | billingTypeDesc }}</span
												>
											</td>
										</tr>
										<tr>
											<th class="">
												<span class="">사용자</span>
											</th>
											<td class="">
												<span class=""
													>{{ item.addUserIntervalPolicyModel.price | currency }}원 / 인
													{{ item.addUserIntervalPolicyModel.billingType | billingTypeDesc }}</span
												>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</label>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import mixinsAlert from '@/components/common/mixinsAlert';
import { countValidate } from '@/components/apply/user/store/addExtension/mixinsCountValidate.js';
import { productClassifier } from '@/views/mixinProductClassifier.js';
export default {
	props: ['value', 'extensionPeriod'],
	mixins: [mixinsAlert, productClassifier, countValidate],
	data() {
		return {
			addonServices: this.value,
		};
	},
	computed: {
		visibleAddonServices() {
			return this.addonServices.filter((obj) => obj.visible);
		},
	},
	watch: {
		value() {
			this.addonServices = this.value;
		},
		addonServices: {
			deep: true,
			handler() {
				this.$emit('input', this.addonServices);
				this.checkRequiredAddonChannel();
			},
		},
	},
	methods: {
		// 연장,추가시에도 공유형 근태연차는 필수이므로 사용중이 아니더라도 기본으로 선택되도록 한다.
		checkRequiredAddonChannel() {
			const visibleAddonServices = this.visibleAddonServices;
			const requireSelectedAddonChannel = visibleAddonServices.filter(
				(addon) => this.isSaasAttendance(addon) && addon.usingCount === 0 && !addon.isSelected,
			);
			requireSelectedAddonChannel.forEach((addon) => {
				console.log(addon.name);
				this.selectAddonService(addon);
			});
		},
		selectAddonService(item) {
			this.$emit('selectAddonChannel', item);
		},
		getItemClass(item) {
			let itemClass = '';
			if (this.isAccountAddonChannel(item)) {
				itemClass = 'ic-accounting';
			} else {
				switch (item.name) {
					case '경비처리(무증빙)':
						itemClass = 'ic-bizplay';
						break;
					case 'TMSe 장비일체형 S':
						itemClass = 'ic-tmse-s';
						break;
					case 'TMSe 장비일체형 E':
						itemClass = 'ic-tmse-e';
						break;
					case 'TMSe 장비일체형 T':
						itemClass = 'ic-tmse-t';
						break;
				}
			}
			return itemClass;
		},
	},
	filters: {
		billingTypeDesc(value) {
			let billingTypeDesc = '';
			switch (value) {
				case 'ONCE':
					billingTypeDesc = '(1회)';
					break;
				case 'MONTHLY':
					billingTypeDesc = '(월)';
					break;
				case 'ESTIMATE':
					billingTypeDesc = '(견적가)';
					break;
			}
			return billingTypeDesc;
		},
	},
};
</script>

<style></style>
