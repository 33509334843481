<template>
	<div class="card list group column">
		<div class="group row">
			<div class="group headline">
				<h4 class="">
					{{ item.displaySectionModel.displayName }}
					<span v-if="item.displaySectionModel.useTooltip" class="">
						<span class="ic-16 ic-info">
							<span class="tooltip-layer left">
								{{ item.displaySectionModel.tooltipDesc }}
							</span>
						</span>
					</span>
					<span v-if="item.displaySectionModel.useIcon" class="badge">
						<span v-if="item.displaySectionModel.iconType == 'HOT'" class="txt">HOT</span>
						<span v-if="item.displaySectionModel.iconType == 'RECOMMENDATION'" class="txt">추천</span>
					</span>
					<span class="badge line primary" v-if="item.usingCount > 0">
						<span class="txt">사용중</span>
					</span>
				</h4>
				<div class="group row" v-if="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity">
					<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
						<span class="price">견적가</span>
					</template>
					<template v-else>
						<span v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'" class="price"
							>{{ item.channelItemSalesSectionModel.price | currency }}원 /{{
								item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit
							}}개</span
						>
						<span v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'" class="price"
							>{{ item.channelItemSalesSectionModel.price | currency }}원</span
						>
					</template>
					<span class="price billing_type">
						{{ item.channelItemSalesSectionModel.billingType | billingTypeDesc }}
					</span>

					<span class="group-inp-num space-between">
						<button class="line mini" v-on:click="checkDownCntOptionItem(item, optionPerService.service)">
							<i class="ic-16 ic-reduce"></i>
						</button>
						<span class="txt">{{ item.orderCount }}</span>
						<button class="line mini" v-on:click="upCntOptionItem(item, optionPerService.service)">
							<i class="ic-16 ic-grow"></i>
						</button>
					</span>
				</div>
				<div class="group row" v-else>
					<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
						<span class="txt">견적가</span>
					</template>
					<template v-else>
						<span class="txt" v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'">
							{{ item.channelItemSalesSectionModel.price | currency }}원 /{{ unitCount(item) }}개
						</span>

						<span class="txt" v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'"
							>{{ item.channelItemSalesSectionModel.price | currency }}원
						</span>
					</template>
					<span class="txt">
						{{ item.channelItemSalesSectionModel.billingType | billingTypeDesc }}
					</span>

					<div class="group-chk" v-if="!item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity">
						<input
							type="checkbox"
							:disabled="isItemDisable(item)"
							:checked="isItemChecked(item)"
							v-on:click="
								item.orderCount == 0
									? upCntOptionItem(item, optionPerService.service)
									: checkDownCntOptionItem(item, optionPerService.service)
							"
						/>
						<label class="label"></label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { option } from '@/components/apply/user/store/addExtension/optionItem/MixinsOption.js';
export default {
	mixins: [option],
	props: ['item', 'optionPerService', 'totUserCnt', 'unitCount'],
};
</script>

<style scoped>
.billing_type {
	margin-left: -10px;
}
</style>
